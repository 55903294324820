import actionTypes from "_constants/actionTypes";
const initialState = { loading: false, queue: 0, APIactions: 0 };

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EMPLOYEES_DATA:
      return {
        ...state,
        employeesListError: null,
        employeesList: action.payload,
      };
    case actionTypes.GET_EMPLOYEES_ERROR:
      return {
        ...state,
        employeesList: null,
        employeesListError: action.payload,
      };

    case actionTypes.GET_EMPLOYEE_DATA:
      return {
        ...state,
        employeeData: action.payload,
      };
    case actionTypes.GET_EMPLOYEE_ERROR:
      return {
        ...state,
        employeeDataError: action.payload,
      };

    case actionTypes.SAVE_EMPLOYEE_DATA:
      return {
        ...state,
        saveEmployeeData: action.payload,
      };
    case actionTypes.SAVE_EMPLOYEE_ERROR:
      return {
        ...state,
        saveEmployeeDataError: action.payload,
      };

    case actionTypes.NEW_EMPLOYEE_DATA:
      return {
        ...state,
        newEmployeeData: action.payload,
      };
    case actionTypes.NEW_EMPLOYEE_ERROR:
      return {
        ...state,
        newEmployeeDataError: action.payload,
      };
    case actionTypes.RESEND_EMPLOYEE_DATA:
      return {
        ...state,
        resendEmployeeData: action.payload,
        resendEmployeeDataError: null
      };
    case actionTypes.RESEND_EMPLOYEE_ERROR:
      return {
        ...state,
        resendEmployeeData: null,
        resendEmployeeDataError: action.payload,
      };
    case actionTypes.CLEAR_DATA_EMPLOYEE:
      return {
        ...state,
        employeeData: null,
        saveEmployeeData: null,
        newEmployeeData: null,
        employeeDataError: null,
        saveEmployeeDataError: null,
        newEmployeeDataError: null,
        resendEmployeeData: null,
        resendEmployeeDataError: null,
      };


      case actionTypes.GET_PERMISSIONS_DATA:
        return {
          ...state,
          getPermissionsError: null,
          getPermissions: action.payload,
        };
      case actionTypes.GET_PERMISSIONS_ERROR:
        return {
          ...state,
          getPermissions: null,
          getPermissionsError: action.payload,
        };
        case actionTypes.SAVE_PERMISSIONS_DATA:          
        return {
            ...state,
            savePermissionDataError: null,
            savePermissionsData: action.payload,
          };
        case actionTypes.SAVE_PERMISSIONS_ERROR:
          return {
            ...state,
            savePermissionData: null,
            savePermissionDataError: action.payload,
          };
          case actionTypes.CLEAR_PERMISSIONS_DATA:
            return {
              ...state,
              savePermissionData: null,
              savePermissionDataError: null,
            };
    default:
      return {
        ...state,
      };
  }
};
