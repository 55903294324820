import actionTypes from "_constants/actionTypes";
const initialState = { loading: false, queue: 0, APIactions: 0 };

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_AUTH_DATA:
      return {
        ...state,
        updateUserAuthError: null,
        updateUserAuth: action.payload,
      };
    case actionTypes.UPDATE_USER_AUTH_ERROR:
      return {
        ...state,
        updateUserAuth: null,
        updateUserAuthError: action.payload,
      };
    case actionTypes.UPDATE_PWD_AUTH_DATA:
      return {
        ...state,
        updatePwdAuthError: null,
        updatePwdAuth: action.payload,
      };
    case actionTypes.UPDATE_PWD_AUTH_ERROR:
      return {
        ...state,
        updatePwdAuth: null,
        updatePwdAuthError: action.payload,
      };
  
    default:
      return {
        ...state,
      };
  }
};
