import actionTypes from "_constants/actionTypes";
const initialState = { loading: false, queue: 0, APIactions: 0 };

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_GUARDS_DATA:
      return {
        ...state,
        guardsListError: null,
        guardsList: action.payload,
      };
    case actionTypes.GET_GUARDS_ERROR:
      return {
        ...state,
        guardsList: null,
        guardsListError: action.payload,
      };

    case actionTypes.GET_GUARD_DATA:
      return {
        ...state,
        guardData: action.payload,
      };
    case actionTypes.GET_GUARD_ERROR:
      return {
        ...state,
        guardDataError: action.payload,
      };

    case actionTypes.SAVE_GUARD_DATA:
      return {
        ...state,
        saveGuardData: action.payload,
      };
    case actionTypes.SAVE_GUARD_ERROR:
      return {
        ...state,
        saveGuardDataError: action.payload,
      };

    case actionTypes.NEW_GUARD_DATA:
      return {
        ...state,
        newGuardData: action.payload,
      };
    case actionTypes.NEW_GUARD_ERROR:
      return {
        ...state,
        newGuardDataError: action.payload,
      };
    case actionTypes.RESEND_GUARD_DATA:
      return {
        ...state,
        resendGuardData: action.payload,
        resendGuardDataError: null
      };
    case actionTypes.RESEND_GUARD_ERROR:
      return {
        ...state,
        resendGuardData: null,
        resendGuardDataError: action.payload,
      };
    case actionTypes.CLEAR_DATA_GUARD:
      return {
        ...state,
        guardData: null,
        saveGuardData: null,
        newGuardData: null,
        guardDataError: null,
        saveGuardDataError: null,
        newGuardDataError: null,
        resendGuardData: null,
        resendGuardDataError: null,
      };
    default:
      return {
        ...state,
      };
  }
};
