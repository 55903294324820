import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { workcenterActions } from "_actions/";
import { Table, Tag, Space, Button, message, Pagination } from "antd";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";

import {
  PlusCircleOutlined,
  EditFilled,
  MailOutlined,
} from "@ant-design/icons";
function ListWorkcenter(props) {
  const { t, i18n } = props;
  const { path } = props.match;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  var workcentersList = useSelector((state) => state.workcenterReducer.workcentersList);
  var workcentersListError = useSelector((state) => state.workcenterReducer.workcentersListError);

  useEffect(() => {
  

    getWorkcenters();
  }, []);

  useEffect(() => {
    setLoading(false);
    if (workcentersList) {
    }
    if (workcentersListError) {
    }
  }, [workcentersList, workcentersListError]);

  const reSend = (item) => {
    dispatch(workcenterActions.resend(item.id));
  };

  const getWorkcenters = () => {
    dispatch(workcenterActions.getWorkcenters());
  };


  const columns = [
    {
      title: t("centers.name"),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t("centers.description"),
      dataIndex: 'description',
      key: 'description',
    },

   

    {
      title:  t("status"),
      key: "actionaa",
      render: (text, record) => (
       <div className={`dot ${record.active ? `isactive`: `isdeactive`} `}  ></div>
      ),
    },
    {
      title: t("actions"),
      key: 'action',
      render: (text, record) => (
        
        <Space size="middle">
          <Link to={`${path}/edit/${record.id}`}><EditFilled /></Link>
        </Space>
      ),
    },
  ];

  const cancel = () => {};

  return (
    <div style={{ padding: 10 }}>
      <h1>{t("menu.centers")}</h1>
      <Link to={`${path}/add`} className="btn btn-sm btn-success mb-2">
        <Button type="primary" icon={<PlusCircleOutlined />}>
          {t("centers.new")}
        </Button>
      </Link>

      <Table columns={columns} dataSource={workcentersList} />

    </div>
  );
}
export default withTranslation()(ListWorkcenter);
