import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "_constants/actionTypes";
import { service } from "../_services/service";
import { history } from "_helpers/history";
export default function* watcherFestivos() {
    yield takeEvery(actionTypes.GET_FESTIVO, getFestivo);
    yield takeEvery(actionTypes.GET_FESTIVOS, getFestivos);
    yield takeEvery(actionTypes.SAVE_FESTIVO, saveFestivo);
    yield takeEvery(actionTypes.NEW_FESTIVO, newFestivo);
    
}



function* getFestivo(payload) {
   yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/festivos/festivo/" + payload.payload;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.getToken(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.GET_FESTIVO_DATA,
        payload: res.data,
      });
      yield put({
        type: actionTypes.CLEAR_DATA_FESTIVO,
        payload: null,
      });


    } else {
      yield put({
        type: actionTypes.GET_FESTIVO_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GET_FESTIVO_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* saveFestivo({payload}) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/festivos/festivo/" + payload.id;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.deleteToken(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.SAVE_FESTIVO_DATA,
        payload: res.data,
      });


      yield put({
        type: actionTypes.CLEAR_DATA_FESTIVO,
        payload: null,
      });
      
    } else {
      yield put({
        type: actionTypes.SAVE_FESTIVO_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.SAVE_FESTIVO_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}


function* newFestivo({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/festivos";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.postToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.NEW_FESTIVO_DATA,
        payload: res.data,
      });

      yield put({
        type: actionTypes.CLEAR_DATA_FESTIVO,
        payload: null,
      });

    } else {
      yield put({
        type: actionTypes.NEW_FESTIVO_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.NEW_FESTIVO_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}


function* getFestivos() {
    yield put({ type: actionTypes.START_REQUEST });
   
    try {
      let apiEndpoint = "/v1/festivos";
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            service.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res) {
        if (res.status && res.status === 200 ) {
          yield put({ type: actionTypes.GET_FESTIVOS_DATA, payload: res.data });
        } else {
          yield put({ type: actionTypes.GET_FESTIVOS_ERROR, payload: "Error" });
        }
      } else {
        yield put({
          type: actionTypes.GET_FESTIVOS_ERROR,
          payload: "Error",
        });
      }
    } catch (e) {
      yield put({
        type: actionTypes.GET_FESTIVOS_ERROR,
        payload: "Error",
      });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }