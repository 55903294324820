import React from 'react';
import { Route, Switch } from 'react-router-dom';

import  ListFestivo from './list';
import  EditFestivo  from './edit';

function Festivo({ match }) {
    const { path } = match;
    
    return (
        <Switch>
            <Route exact path={path} component={ListFestivo} />
            <Route path={`${path}/add`} component={EditFestivo} />
        </Switch>
    );
}

export { Festivo };