import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { employeeActions } from "_actions/";
import { Table, Tag, Space, Button, message, Pagination,Popconfirm } from "antd";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import moment from "moment";

import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditFilled,PlusCircleOutlined
  ,
  MailOutlined,
} from "@ant-design/icons";
function ListPermission(props) {
  const { t, i18n } = props;
  const { path } = props.match;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  var getPermissions = useSelector(
    (state) => state.employeeReducer.getPermissions
  );
  var getPermissionsError = useSelector(
    (state) => state.employeeReducer.getPermissionsError
  );

  var savePermissionData = useSelector(
    (state) => state.employeeReducer.savePermissionData
  );
  var savePermissionDataError = useSelector(
    (state) => state.employeeReducer.savePermissionDataError
  );
  useEffect(() => {
    getPermissionsLista();
  }, [savePermissionData, savePermissionDataError]);

  useEffect(() => {
    getPermissionsLista();
  }, []);

  useEffect(() => {
    setLoading(false);
    if (getPermissions) {
    }
    if (getPermissionsError) {
    }
  }, [getPermissions, getPermissionsError]);

  const getPermissionsLista = () => {
    dispatch(employeeActions.getPermissions());
  };

  const appr = (record) => {
    dispatch(employeeActions.updatePermission(record.id));
  };

  const del = (record) => {
    dispatch(employeeActions.delPermission(record.id));
  };
  const columns = [
    {
      title: t("permissions.employee"),
      dataIndex: "approved",
      key: "approved",
      render: (text, record) => (
        <span>
          {record.user.name} {record.user.surname}
        </span>
      ),
    },
    {
      title: t("permissions.day"),
      dataIndex: "approved",
      key: "approved",
      render: (text, record) => (
        <span>{moment(record.createdAt).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      title: t("permissions.from"),
      dataIndex: "from",
      key: "from",
      render: (text, record) => (
        <span>{moment(record.initDate).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      title: t("permissions.to"),
      dataIndex: "to",
      key: "to",
      render: (text, record) => (
        <span>{moment(record.endDate).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      title: t("actions"),
      dataIndex: "approved",
      key: "approved",
      render: (text, record) =>
        !record.approvedById ? (
          <>
            <CheckCircleOutlined
              style={{ cursor: "pointer" }}
              onClick={() => appr(record)}
            />
            <Popconfirm
              title="Are you sure to reject this permission?"
              onConfirm={() => del(record)}
              onCancel={null}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined style={{ cursor: "pointer" }} />
            </Popconfirm>
          </>
        ) : (
          <span>
            {t("permissions.approved")} ({record.approvedBy?.name})
          </span>
        ),
    },
  ];

  const cancel = () => {};

  return (
    <div style={{ padding: 10 }}>
      <h1>{t("menu.permissions")}</h1>
      <Link to={`${path}/add`} className="btn btn-sm btn-success mb-2">
        <Button type="primary" icon={<PlusCircleOutlined />}>
          {t("permissions.new")}
        </Button>
      </Link>

      <Table columns={columns} dataSource={getPermissions} />
    </div>
  );
}
export default withTranslation()(ListPermission);
