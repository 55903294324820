import actionTypes from "_constants/actionTypes";
const initialState = { loading: false, queue: 0, APIactions: 0 };

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WORKCENTERS_DATA:
      return {
        ...state,
        workcentersListError: null,
        workcentersList: action.payload,
      };
    case actionTypes.GET_WORKCENTERS_ERROR:
      return {
        ...state,
        workcentersList: null,
        workcentersListError: action.payload,
      };

    case actionTypes.GET_WORKCENTER_DATA:
      return {
        ...state,
        workcenterData: action.payload,
      };
    case actionTypes.GET_WORKCENTER_ERROR:
      return {
        ...state,
        workcenterDataError: action.payload,
      };

    case actionTypes.SAVE_WORKCENTER_DATA:
      return {
        ...state,
        saveWorkcenterData: action.payload,
      };
    case actionTypes.SAVE_WORKCENTER_ERROR:
      return {
        ...state,
        saveWorkcenterDataError: action.payload,
      };

    case actionTypes.NEW_WORKCENTER_DATA:
      return {
        ...state,
        newWorkcenterData: action.payload,
      };
    case actionTypes.NEW_WORKCENTER_ERROR:
      return {
        ...state,
        newWorkcenterDataError: action.payload,
      };
    case actionTypes.RESEND_WORKCENTER_DATA:
      return {
        ...state,
        resendWorkcenterData: action.payload,
        resendWorkcenterDataError: null
      };
    case actionTypes.RESEND_WORKCENTER_ERROR:
      return {
        ...state,
        resendWorkcenterData: null,
        resendWorkcenterDataError: action.payload,
      };
    case actionTypes.CLEAR_DATA_WORKCENTER:
      return {
        ...state,
        workcenterData: null,
        saveWorkcenterData: null,
        newWorkcenterData: null,
        workcenterDataError: null,
        saveWorkcenterDataError: null,
        newWorkcenterDataError: null,
        resendWorkcenterData: null,
        resendWorkcenterDataError: null,
      };
    default:
      return {
        ...state,
      };
  }
};
