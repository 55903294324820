import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { guardsActions, employeeActions } from "_actions/";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { history } from "_helpers/history";
import * as Yup from "yup";
import * as _ from "lodash";
import { DatePicker } from "antd";

import {
  Form,
  Space,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  InputNumber,
  Col,
  Checkbox,
  Button,
  message,
  AutoComplete,
} from "antd";

const { Option } = Select;
const { RangePicker } = DatePicker;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function EditGuard(props) {
  const { path } = props.match;
  const { id } = props.match.params;
  const { t, i18n } = props;
  const isAddMode = !id;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [guard, setGuard] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [employeesOrigin, setEmployeesOrigin] = useState([]);
  const [checked, setChecked] = useState(true);
  var employeesList = useSelector(
    (state) => state.employeeReducer.employeesList
  );
  var guardData = useSelector((state) => state.guardReducer.guardData);

  var newGuardData = useSelector((state) => state.guardReducer.newGuardData);

  var saveGuardData = useSelector((state) => state.guardReducer.saveGuardData);

  var newGuardDataError = useSelector(
    (state) => state.guardReducer.newGuardDataError
  );

  var saveGuardDataError = useSelector(
    (state) => state.guardReducer.saveGuardDataError
  );


  useEffect(() => {
    if (employeesList) {
      setEmployees(employeesList);
      setEmployeesOrigin(employeesList);
    }
  }, [employeesList]);

  const onCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const getEmployees = () => {
    dispatch(employeeActions.getEmployees());
  };

  useEffect(() => {
    setGuard(guardData);
    console.log(guardData);

    const { setFieldsValue, getFieldValue } = form;

    if (guardData) {
      setChecked(guardData.active);
    }

    setFieldsValue(guardData);
  }, [guardData]);

  useEffect(() => {
    if (newGuardDataError) message.error(newGuardDataError.msg);

    if (saveGuardDataError) message.error(saveGuardDataError.msg);
  }, [newGuardDataError, saveGuardDataError]);

  useEffect(() => {
    if (newGuardData || saveGuardData) message.success(t("saved"));

    if (saveGuardData) history.push("..");

    if (newGuardData) history.push(".");
  }, [newGuardData, saveGuardData]);

  useEffect(() => {
    if (!isAddMode) {
      // get guard and set form fields
      dispatch(guardsActions.getGuard(id));
    } else {
      const { setFieldsValue, getFieldValue } = form;

      setFieldsValue(guardData);
    }
  }, []);

  const onFinish = (data) => {
    return isAddMode ? create(data) : update(id, data);
  };

  function onSubmit(data) {}

  function create(data) {
  data.active = true;
    dispatch(guardsActions.newGuard(data));
  }

  function update(id, data) {
    data.id = id;
    data.active = checked;
    console.log(data);
    dispatch(guardsActions.updateGuard(data));
  }

  const handleSearch = (value) => {
    if (value) {
      const filterTable = employees.filter((item) => {
        console.log(88, item);
        if (!item) {
          return false;
        }
        return (
          item &&
          item.name &&
          item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
        );
      });

      setEmployees(filterTable);
    } else {
      setEmployees(employeesOrigin);
    }
  };

  const options = employees.map((d) => {
    if (d.active) {
      return (
        <Option value={d.id} key={d.id}>
          {d.name} {d.surname}
        </Option>
      );
    }
  });

  return (
    <div style={{ padding: 10 }}>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={{
          ["sessionslimit"]: "0",
        }}
      >
        <Form.Item
          name="userId"
          label={t("guards.employee")}
          hasFeedback
          rules={[{ required: true, message: t("required") }]}
        >
          <Select
          
            placeholder={t("guards.employee")}
            showSearch={true}
            defaultActiveFirstOption={true}
            filterOption={false}
            onSearch={handleSearch}
            notFoundContent={null}
          >
            {options}
          </Select>
        </Form.Item>
        <Form.Item
          name="dates"
          label={t("guards.date")}
          rules={[
            {
              required: true,
              message: t("required"),
            },
          ]}
        >
          <RangePicker />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            {t("save")}
          </Button>

          <Link to={isAddMode ? "." : ".."}>
            <Button>{t("cancel")}</Button>
          </Link>
        </Form.Item>
      </Form>
    </div>
  );
}
export default withTranslation()(EditGuard);
