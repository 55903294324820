import actionTypes from "_constants/actionTypes";

export const dashboardActions = {
    getDashboard,
  endTransaction
};
function endTransaction() {
  return {
    type: actionTypes.END_TRANSACTION,
  };
}
function getDashboard() {
  return {
    type: actionTypes.GET_DASHBOARD,
    payload: "",
  };
}
