import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { employeeActions, workcenterActions } from "_actions/";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { history } from "_helpers/history";
import * as Yup from "yup";
import * as _ from "lodash";
import {
  Form,
  Space,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  InputNumber,
  Col,
  Checkbox,
  Button,
  message,
  AutoComplete,
} from "antd";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function EditEmployee(props) {
  const { path } = props.match;
  const { id } = props.match.params;
  const { t, i18n } = props;
  const isAddMode = !id;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState(null);
  const [centers, setCenters] = useState([]);
  const [centersOrigin, setCentersOrigin] = useState([]);
  const [checked, setChecked] = useState(true);
  var employeeData = useSelector((state) => state.employeeReducer.employeeData);

  var newEmployeeData = useSelector((state) => state.employeeReducer.newEmployeeData);

  var saveEmployeeData = useSelector((state) => state.employeeReducer.saveEmployeeData);

  var newEmployeeDataError = useSelector(
    (state) => state.employeeReducer.newEmployeeDataError
  );

  var saveEmployeeDataError = useSelector(
    (state) => state.employeeReducer.saveEmployeeDataError
  );

  const onCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };


  var workcentersList = useSelector((state) => state.workcenterReducer.workcentersList);
  useEffect(() => {
    if (!workcentersList) getWorkcenters();
  }, []);

  const getWorkcenters = () => {
    dispatch(workcenterActions.getWorkcenters());
  };


  useEffect(() => {
    if (workcentersList) {
      setCenters(workcentersList);
      setCentersOrigin(workcentersList);
    }
  }, [workcentersList]);

  useEffect(() => {
    setEmployee(employeeData);
    console.log(employeeData);

    const { setFieldsValue, getFieldValue } = form;

    if (employeeData) {
      setChecked(employeeData.active);
    }

    setFieldsValue(employeeData);
  }, [employeeData]);

  useEffect(() => {
    if (newEmployeeDataError) message.error(newEmployeeDataError.msg);

    if (saveEmployeeDataError) message.error(saveEmployeeDataError.msg);
  }, [newEmployeeDataError, saveEmployeeDataError]);

  useEffect(() => {
    if (newEmployeeData || saveEmployeeData) message.success(t("saved"));

    if (saveEmployeeData) history.push("..");

    if (newEmployeeData) history.push(".");
  }, [newEmployeeData, saveEmployeeData]);

  useEffect(() => {
    if (!isAddMode) {
      // get employee and set form fields
      dispatch(employeeActions.getEmployee(id));
    } else {
      const { setFieldsValue, getFieldValue } = form;

      setFieldsValue(employeeData);
    }
  }, []);

  const onFinish = (data) => {
    return isAddMode ? create(data) : update(id, data);
  };

  function onSubmit(data) {}

  function create(data) {
    console.log(222, data);
    var comp = centersOrigin.filter(
      (item) => "" + item.id === "" + data.centerId
    );
    console.log(111, centersOrigin);
    console.log(222, comp);
    if (comp && comp[0]) {
      data.center = comp[0].name;
      data.centerIdHubspot = comp[0].idHubSpot;
      data.centerDomain = comp[0].subdomain;
    }
    data.active = true;
    dispatch(employeeActions.newEmployee(data));
  }

  function update(id, data) {
    data.id = id;
    data.active = checked;
    console.log(data);
    dispatch(employeeActions.updateEmployee(data));
  }

  const handleSearch = (value) => {
    if (value) {
      const filterTable = centers.filter((item) => {
        console.log(88, item);
        if (!item) {
          return false;
        }
        return (
          item &&
          item.name &&
          item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
        );
      });

      setCenters(filterTable);
    } else {
      setCenters(centersOrigin);
    }
  };

  const options = centers.map((d) => {
    if (d.active) {
      return (
        <Option value={d.id} key={d.id}>
          {d.name}
        </Option>
      );
    }
  });

  return (
    <div style={{ padding: 10 }}>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={{
          ["sessionslimit"]: "0",
        }}
      >
        <Form.Item
          name="name"
          label={t("employee.name")}
          rules={[
            {
              required: true,
              message: t("required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="surname"
          label={t("employee.surname")}
          rules={[
            {
              required: true,
              message: t("required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      
        <Form.Item
          name="email"
          label={t("employee.email")}
          rules={[
            {
              type: "email",
              message: t("login.emailIncorrect"),
            },
            {
              required: true,
              message: t("required"),
            },
          ]}
        >
          <Input disabled={!isAddMode} />
        </Form.Item>

        <Form.Item
          name="nif"
          label={t("employee.nif")}
          rules={[
            {
              required: true,
              message: t("required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      
        <Form.Item
          name="afiliation"
          label={t("employee.afiliation")}
          rules={[
            {
              required: true,
              message: t("required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      
        <Form.Item
          name="phone"
          label={t("employee.phone")}
          rules={[
            {
              required: true,
              message: t("required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="centerId"
          label={t("employee.center")}
          hasFeedback
          rules={[{ required: true, message: t("required") }]}
        >
          <Select
          
            placeholder={t("employee.center")}
            showSearch={true}
            defaultActiveFirstOption={true}
            filterOption={false}
            onSearch={handleSearch}
            notFoundContent={null}
          >
            {options}
          </Select>
        </Form.Item>
       
        <Form.Item
          name="profile"
          label={t("employee.profile")}
          hasFeedback
          rules={[{ required: true, message: t("required") }]}
        >
          <Select placeholder={t("employee.profile")}>
            <Option value={"employee"}>Empleado</Option>
            <Option value={"manager"}>Manager</Option>
            <Option value={"admin"}>Admin</Option>
          </Select>
        </Form.Item>

        <Form.Item valuePropName="active" name="active" label={t("active")}>
          <Checkbox checked={checked} onChange={onCheckboxChange} />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            {t("save")}
          </Button>

          <Link to={isAddMode ? "." : ".."}>
            <Button>{t("cancel")}</Button>
          </Link>
        </Form.Item>
      </Form>
    </div>
  );
}
export default withTranslation()(EditEmployee);
