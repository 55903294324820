import actionTypes from "_constants/actionTypes";
const initialState = { loading: false, queue: 0, APIactions: 0 };

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FESTIVOS_DATA:
      return {
        ...state,
        festivosListError: null,
        festivosList: action.payload,
      };
    case actionTypes.GET_FESTIVOS_ERROR:
      return {
        ...state,
        festivosList: null,
        festivosListError: action.payload,
      };

    case actionTypes.GET_FESTIVO_DATA:
      return {
        ...state,
        festivoData: action.payload,
      };
    case actionTypes.GET_FESTIVO_ERROR:
      return {
        ...state,
        festivoDataError: action.payload,
      };

    case actionTypes.SAVE_FESTIVO_DATA:
      return {
        ...state,
        saveFestivoData: action.payload,
      };
    case actionTypes.SAVE_FESTIVO_ERROR:
      return {
        ...state,
        saveFestivoDataError: action.payload,
      };

    case actionTypes.NEW_FESTIVO_DATA:
      return {
        ...state,
        newFestivoData: action.payload,
      };
    case actionTypes.NEW_FESTIVO_ERROR:
      return {
        ...state,
        newFestivoDataError: action.payload,
      };
    case actionTypes.RESEND_FESTIVO_DATA:
      return {
        ...state,
        resendFestivoData: action.payload,
        resendFestivoDataError: null
      };
    case actionTypes.RESEND_FESTIVO_ERROR:
      return {
        ...state,
        resendFestivoData: null,
        resendFestivoDataError: action.payload,
      };
    case actionTypes.CLEAR_DATA_FESTIVO:
      return {
        ...state,
        festivoData: null,
        saveFestivoData: null,
        newFestivoData: null,
        festivoDataError: null,
        saveFestivoDataError: null,
        newFestivoDataError: null,
        resendFestivoData: null,
        resendFestivoDataError: null,
      };
    default:
      return {
        ...state,
      };
  }
};
