import React, { Fragment, useState, useEffect, PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import { history } from "_helpers/history";
import {
  Menu,
  Layout,
  Avatar,
  Popover,
  Badge,
  List,
  Row,
  Col,
  Dropdown,
} from "antd";
import {
  BellOutlined,
  RightOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MailFilled,
  UserOutlined,
} from "@ant-design/icons";
import logo from "assets/img/logow.png";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "_actions";

const { SubMenu } = Menu;
const { Header } = Layout;
function HeaderLayout(props) {
  console.log(props);
  const collapsed = props.collapsed;
  const toggle = props.toggle;
  const user = JSON.parse(localStorage.getItem("orionBKUser"));

  const name = user ? (user.name + " " + user.surname) : "CC CC";
  const dispatch = useDispatch();
  const [fixed, setFixed] = useState(false);
  const { t, i18n } = useTranslation();

  const logout = () => {
    dispatch(authActions.logout());
  };
  const rightContent = () => {
    return (
      <Menu key="user" mode="horizontal">
        <SubMenu
          title={
            <Fragment>
              <span style={{ color: "#999", marginRight: 4 }}></span>
              <span>Hola</span>
            </Fragment>
          }
        >
          <Menu.Item key="SignOut">Sign out</Menu.Item>
        </SubMenu>
      </Menu>
    );
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <a href="/admin/profile">{t("profile")}</a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" onClick={logout}>
        {t("logout")}
      </Menu.Item>
    </Menu>
  );

console.log("---->", user)

console.log("---->", user.name)

console.log("---->", name)

  return (
    <>
      <Header className="layout-page-header" id="layoutHeader">
        <div className="logo" style={{ width: collapsed ? 80 : 170 }}>
          <img src={logo} />
        </div>
        <div className="layout-page-header-main">
          <div onClick={toggle}></div>
          <div className="actions">
            <Dropdown overlay={menu} trigger={["click"]}>
              <span className="user-action">
                <Avatar style={{ color: "#000", backgroundColor: "#fff" }}>
                  {name && name
                    .split(" ")
                    .map((n) => n[0])
                    .join("")}
                </Avatar>
              </span>
            </Dropdown>
          </div>
        </div>
      </Header>
    </>
  );
}

Header.propTypes = {
  fixed: PropTypes.bool,
  user: PropTypes.object,
  menus: PropTypes.array,
  collapsed: PropTypes.bool,
  onSignOut: PropTypes.func,
  notifications: PropTypes.array,
  onCollapseChange: PropTypes.func,
  onAllNotificationsRead: PropTypes.func,
};

export default withTranslation()(HeaderLayout);
