export default {
  API_DONE: "API_DONE",
  START_REQUEST: "START_REQUEST",
  END_REQUEST: "END_REQUEST",
  RESET_LOADING: "RESET_LOADING",
  INCREMENT_COUNTER: "INCREMENT_COUNTER",
  DECREMENT_COUNTER: "DECREMENT_COUNTER",
  INCREMENT_COUNTER_DOUBLE: "INCREMENT_COUNTER_DOUBLE",
  DECREMENT_COUNTER_DOUBLE: "DECREMENT_COUNTER_DOUBLE",
  END_TRANSACTION: "END_TRANSACTION",
  LOGOUT: "LOGOUT",
  LOGIN_USER: "LOGIN_USER",
  LOGIN_USER_DATA: "LOGIN_USER_DATA",
  LOGIN_USER_ERROR: "LOGIN_USER_ERROR",
  CHANGEPASS_USER: "CHANGEPASS_USER",
  CHANGEPASS_USER_DATA: "CHANGEPASS_USER_DATA",
  CHANGEPASS_USER_ERROR: "CHANGEPASS_USER_ERROR",
  RESET_USER: "RESET_USER",
  RESET_USER_DATA: "RESET_USER_DATA",
  RESET_USER_ERROR: "RESET_USER_ERROR",
  
  GET_DASHBOARD: "GET_DASHBOARD",
  GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
  GET_DASHBOARD_ERROR: "GET_DASHBOARD_ERROR",


  UPDATE_USER_AUTH: "UPDATE_USER_AUTH",
  UPDATE_USER_AUTH_DATA: "UPDATE_USER_AUTH_DATA",
  UPDATE_USER_AUTH_ERROR: "UPDATE_USER_AUTH_ERROR",

  UPDATE_PWD_AUTH: "UPDATE_PWD_AUTH",
  UPDATE_PWD_AUTH_DATA: "UPDATE_PWD_AUTH_DATA",
  UPDATE_PWD_AUTH_ERROR: "UPDATE_PWD_AUTH_ERROR",
  
  GET_EMPLOYEES: "GET_EMPLOYEES",
  GET_EMPLOYEES_DATA: "GET_EMPLOYEES_DATA",
  GET_EMPLOYEES_ERROR: "GET_EMPLOYEES_ERROR",

  GET_EMPLOYEE: "GET_EMPLOYEE",
  GET_EMPLOYEE_DATA: "GET_EMPLOYEE_DATA",
  GET_EMPLOYEE_ERROR: "GET_EMPLOYEE_ERROR",


  RESEND_EMPLOYEE: "RESENDEMPLOYEE",
  RESEND_EMPLOYEE_DATA: "RESEND_EMPLOYEE_DATA",
  RESEND_EMPLOYEE_ERROR: "RESEND_EMPLOYEE_ERROR",


  SAVE_EMPLOYEE: "SAVE_EMPLOYEE",
  SAVE_EMPLOYEE_DATA: "SAVE_EMPLOYEE_DATA",
  SAVE_EMPLOYEE_ERROR: "SAVE_EMPLOYEE_ERROR",

  NEW_EMPLOYEE: "NEW_EMPLOYEE",
  NEW_EMPLOYEE_DATA: "NEW_EMPLOYEE_DATA",
  NEW_EMPLOYEE_ERROR: "NEW_EMPLOYEE_ERROR",

  CLEAR_DATA_EMPLOYEE: "CLEAR_DATA_EMPLOYEE",



  GET_WORKCENTERS: "GET_WORKCENTERS",
  GET_WORKCENTERS_DATA: "GET_WORKCENTERS_DATA",
  GET_WORKCENTERS_ERROR: "GET_WORKCENTERS_ERROR",

  GET_WORKCENTER: "GET_WORKCENTER",
  GET_WORKCENTER_DATA: "GET_WORKCENTER_DATA",
  GET_WORKCENTER_ERROR: "GET_WORKCENTER_ERROR",


  RESEND_WORKCENTER: "RESENDWORKCENTER",
  RESEND_WORKCENTER_DATA: "RESEND_WORKCENTER_DATA",
  RESEND_WORKCENTER_ERROR: "RESEND_WORKCENTER_ERROR",


  SAVE_WORKCENTER: "SAVE_WORKCENTER",
  SAVE_WORKCENTER_DATA: "SAVE_WORKCENTER_DATA",
  SAVE_WORKCENTER_ERROR: "SAVE_WORKCENTER_ERROR",

  NEW_WORKCENTER: "NEW_WORKCENTER",
  NEW_WORKCENTER_DATA: "NEW_WORKCENTER_DATA",
  NEW_WORKCENTER_ERROR: "NEW_WORKCENTER_ERROR",

  CLEAR_DATA_WORKCENTER: "CLEAR_DATA_WORKCENTER",



  GET_GUARDS: "GET_GUARDS",
  GET_GUARDS_DATA: "GET_GUARDS_DATA",
  GET_GUARDS_ERROR: "GET_GUARDS_ERROR",

  GET_GUARD: "GET_GUARD",
  GET_GUARD_DATA: "GET_GUARD_DATA",
  GET_GUARD_ERROR: "GET_GUARD_ERROR",


  RESEND_GUARD: "RESENDGUARD",
  RESEND_GUARD_DATA: "RESEND_GUARD_DATA",
  RESEND_GUARD_ERROR: "RESEND_GUARD_ERROR",


  SAVE_GUARD: "SAVE_GUARD",
  SAVE_GUARD_DATA: "SAVE_GUARD_DATA",
  SAVE_GUARD_ERROR: "SAVE_GUARD_ERROR",

  NEW_GUARD: "NEW_GUARD",
  NEW_GUARD_DATA: "NEW_GUARD_DATA",
  NEW_GUARD_ERROR: "NEW_GUARD_ERROR",

  CLEAR_DATA_GUARD: "CLEAR_DATA_GUARD",

  GET_TIMESHEET: "GET_TIMESHEET",
  GET_TIMESHEET_DATA: "GET_TIMESHEET_DATA",
  GET_TIMESHEET_ERROR: "GET_TIMESHEET_ERROR",
  GET_CLEAR_TIMESHEET_DATA: "GET_CLEAR_TIMESHEET_DATA",


  GET_PERMISSIONS:"GET_PERMISSIONS",
  GET_PERMISSIONS_DATA:"GET_PERMISSIONS_DATA",
  GET_PERMISSIONS_ERROR:"GET_PERMISSIONS_ERROR",

  SAVE_PERMISSIONS:"SAVE_PERMISSIONS",
  SAVE_PERMISSIONS_DATA:"SAVE_PERMISSIONS_DATA",
  SAVE_PERMISSIONS_ERROR:"SAVE_PERMISSIONS_ERROR",
  CLEAR_PERMISSIONS_DATA:"CLEAR_PERMISSIONS_DATA",

  DEL_PERMISSIONS:"DEL_PERMISSIONS",



  GET_FESTIVOS: "GET_FESTIVOS",
  GET_FESTIVOS_DATA: "GET_FESTIVOS_DATA",
  GET_FESTIVOS_ERROR: "GET_FESTIVOS_ERROR",

  GET_FESTIVO: "GET_FESTIVO",
  GET_FESTIVO_DATA: "GET_FESTIVO_DATA",
  GET_FESTIVO_ERROR: "GET_FESTIVO_ERROR",


  RESEND_FESTIVO: "RESENDFESTIVO",
  RESEND_FESTIVO_DATA: "RESEND_FESTIVO_DATA",
  RESEND_FESTIVO_ERROR: "RESEND_FESTIVO_ERROR",


  SAVE_FESTIVO: "SAVE_FESTIVO",
  SAVE_FESTIVO_DATA: "SAVE_FESTIVO_DATA",
  SAVE_FESTIVO_ERROR: "SAVE_FESTIVO_ERROR",

  NEW_FESTIVO: "NEW_FESTIVO",
  NEW_FESTIVO_DATA: "NEW_FESTIVO_DATA",
  NEW_FESTIVO_ERROR: "NEW_FESTIVO_ERROR",

  CLEAR_DATA_FESTIVO: "CLEAR_DATA_FESTIVO",

};
