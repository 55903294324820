import axios from "axios";
import config from "_config/config";

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = localStorage.getItem("orionBKToken");
      console.log(15,token);
      if (token) {
        config.headers = {
          Authorization: `bearer ${token}`,
          "Content-Type": "application/json",
        };
      }
    }
    return config;
  },
  (error) => {
    console.log(error);
    // store.dispatch(setAPIErrorMessage(error.message));
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    const refreshToken = localStorage.getItem("orionBKRefreshToken");
    const user = JSON.parse(localStorage.getItem("orionBKUser"));
   
    if (refreshToken && user) {
      if (error.response.status === 498 && !originalRequest._retry) {
        originalRequest._retry = true;

        return axios
          .post(config.backendUrl + "/v1/auth/refresh", {
            refreshToken: refreshToken,
            username: user.email,
          })
          .then(({data}) => {
            localStorage.setItem("orionBKToken", data.token);
            localStorage.setItem(
              "orionBKRefreshToken",
              data.refreshToken
            );

            axios.defaults.headers.common["Authorization"] =
              "Bearer " + data.token;
            originalRequest.headers["Authorization"] =
              "Bearer " + data.token;
            return axiosInstance(originalRequest);
          });
      }
    }

    return Promise.reject(error);
  }
);
