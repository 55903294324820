import React from 'react';
import { Route, Switch } from 'react-router-dom';

import  ListPermission from './list';
import AddPermission from './edit'

function Permission({ match }) {
    const { path } = match;
    return (
        <Switch>
            <Route exact path={path} component={ListPermission} />

            <Route path={`${path}/add`} component={AddPermission} />
        </Switch>
    );
}

export { Permission };