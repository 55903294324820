import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "_constants/actionTypes";
import { service } from "../_services/service";
import { history } from "_helpers/history";
export default function* watcherAuthentication() {
  yield takeEvery(actionTypes.LOGIN_USER, loginUser);
  yield takeEvery(actionTypes.LOGOUT, logoutUser);
  //yield takeEvery(actionTypes.CHANGEPASS_USER, changePass);
  yield takeEvery(actionTypes.RESET_USER, resetUser);

}


function* resetUser({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/auth/forgot";
    // console.log(payload);
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.post(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.RESET_USER_DATA,
        payload: res.data,
      });
    } else {
      // console.log(res);
      yield put({
        type: actionTypes.RESET_USER_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.RESET_USER_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* logoutUser() {
  yield put({ type: actionTypes.START_REQUEST });
  localStorage.setItem("orionBKAuth", "false");
  localStorage.setItem("orionBKCompleto", "0");
  localStorage.removeItem("orionBKToken");
  localStorage.removeItem("orionBKRefreshToken");
  localStorage.removeItem("orionBKUser");
  yield put({ type: actionTypes.END_REQUEST });
  history.push("/auth/login");
}

function* loginUser({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/auth/login";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.post(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res) {
      if (res.status && res.status === 200 && res.data.token) {
        if (res.data.user.profile !== "admin") {
          yield put({
            type: actionTypes.LOGIN_USER_ERROR,
            payload: "No permitido",
          });
        } else {
          localStorage.setItem("orionBKAuth", "true");
          localStorage.setItem("orionBKCompleto", "0");
          localStorage.setItem("orionBKToken", res.data.token);
          localStorage.setItem("orionBKRefreshToken", res.data.refreshToken);
          localStorage.setItem("orionBKUser", JSON.stringify(res.data.user));
          localStorage.setItem(
            "orionBKTour",
            localStorage.getItem("orionBKTour") !== (undefined && null)
              ? localStorage.getItem("orionBKTour")
              : true
          );
          yield put({ type: actionTypes.LOGIN_USER_DATA, payload: res.data });
          history.push("/admin/dashboard");
        }
      } else {
        yield put({ type: actionTypes.LOGIN_USER_ERROR, payload: res.data });
      }
    } else {
      yield put({
        type: actionTypes.LOGIN_USER_ERROR,
        payload: {codeMsg: "001", msg: "Se ha producido un error"},
      });
    }
  } catch (e) {
    yield put({
      type: actionTypes.LOGIN_USER_ERROR,
      payload: "Se ha producido un error",
    });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
