import actionTypes from "_constants/actionTypes";

export const festivosActions = {
  getFestivos,
  endTransaction,
  newFestivo,
  updateFestivo,
  getFestivo,
  
};
function endTransaction() {
  return {
    type: actionTypes.END_TRANSACTION,
  };
}

function getFestivos() {
  return {
    type: actionTypes.GET_FESTIVOS,
    payload: "",
  };
}

function newFestivo(payload) {
  
  return {
    type: actionTypes.NEW_FESTIVO,
    payload: payload,
  };
}

function updateFestivo(payload) {
  return {
    type: actionTypes.SAVE_FESTIVO,
    payload: payload,
  };
}

function getFestivo(id) {
  return {
    type: actionTypes.GET_FESTIVO,
    payload: id,
  };
}

