import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import  LoginPage from "views/auth/login.page";
import  ForgotPage from "views/auth/forgot.page";
import  DashboardPage from "views/admin/dashboard";
import {Employee} from "views/admin/employee";
import {Workcenter} from "views/admin/workcenter";
import {Guard} from "views/admin/guards";
import {Festivo} from "views/admin/festivos";
import TimeSheetView from "views/admin/timesheet"
import Profile from "views/admin/profile";
import {Permission} from "views/admin/permissions";
import AddFichaje from  "views/admin/timesheet/edit"
import {  SolutionOutlined,
  PieChartOutlined,
  SmileOutlined,
  TeamOutlined,LockOutlined} from "@ant-design/icons"
const dashboardRoutes = [
    {
      path: "/login",
      name: "Login",
 
      component: LoginPage,
      layout: "/auth",
    },
    {
      path: "/forgot",
      name: "Forgot",
     
      component: ForgotPage,
      layout: "/auth",
    },
    {
      path: "/dashboard",
      name: "Dashboard",
     
      component: DashboardPage,
      layout: "/admin",
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      layout: "/admin",
    },
    {
      path: "/employees",
      name: "Employee",
      component: Employee,
      layout: "/admin",
    },
    {
      path: "/festivos",
      name: "Festivo",
      component: Festivo,
      layout: "/admin",
    },

    {
      path: "/permissions",
      name: "Permission",
      component: Permission,
      layout: "/admin",
    },
    
    {
      path: "/workcenters",
      name: "Workcenter",
      component: Workcenter,
      layout: "/admin",
    },
    {
      path: "/guards",
      name: "Guard",
      component: Guard,
      layout: "/admin",
    },

    {
      path: "/timesheet",
      name: "TimeSheetView",
      component: TimeSheetView,
      layout: "/admin",
    },

    {
      path: "/timesheetadd",
      name: "AddFichaje",
      component: AddFichaje,
      layout: "/admin",
    }
    
    
    
    
];

export default dashboardRoutes;