import actionTypes from "_constants/actionTypes";

export const workcenterActions = {
  getWorkcenters,
  endTransaction,
  newWorkcenter,
  updateWorkcenter,
  getWorkcenter
};
function endTransaction() {
  return {
    type: actionTypes.END_TRANSACTION,
  };
}

function getWorkcenters() {
  return {
    type: actionTypes.GET_WORKCENTERS,
    payload: "",
  };
}

function newWorkcenter(payload) {
  
  return {
    type: actionTypes.NEW_WORKCENTER,
    payload: payload,
  };
}

function updateWorkcenter(payload) {
  return {
    type: actionTypes.SAVE_WORKCENTER,
    payload: payload,
  };
}

function getWorkcenter(id) {
  return {
    type: actionTypes.GET_WORKCENTER,
    payload: id,
  };
}

