import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import routes from "routes.js";
import Footer from "components/Footer/Footer.js";
import HeaderLayout from "components/Header/Header.js";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Layout, Menu, Breadcrumb, Drawer } from "antd";
import "./Admin.scss";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import { history } from "_helpers/history";
import { Link } from "react-router-dom";
import { matchPath } from "react-router";

import {CalendarOutlined,
  SolutionOutlined,ForkOutlined,
  PieChartOutlined,
  SmileOutlined,
  TeamOutlined,
  DeploymentUnitOutlined,FileExcelOutlined,
  ApartmentOutlined,
  LaptopOutlined
} from "@ant-design/icons";
import logo from "assets/img/logo.png";

const { Sider, Content } = Layout;
const { SubMenu } = Menu;

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/auth" to="/auth/login" />
  </Switch>
);

function Admin({ ...rest }) {
  const [collapsed, setCollapsed] = useState(false);
  const { t, i18n } = useTranslation();
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const toggle = () => {

    setCollapsed(!collapsed);
  };


  const handleClick = (e) => {
    console.log("click ", e);
    switch (e.key) {
      case "3":
        history.push("/companies");
      default:
        history.push("/admin/dashboard");
    }
  };

  const menuItems = [
    {
      name: "menu.dashboard",
      icon: <PieChartOutlined />,
      link: "/admin/dashboard",
      key: 1,
    },
    {
      name: "menu.employees",
      icon: <TeamOutlined />,
      link: "/admin/employees",
      key: 2,
    },
    {
      name: "menu.centers",
      icon: <SolutionOutlined />,
      link: "/admin/workcenters",
      key: 3,
    },
    {
      name: "menu.guards",
      icon: <SolutionOutlined />,
      link: "/admin/guards",
      key: 4,
    },
    {
      name: "menu.festivos",
      icon: <CalendarOutlined />,
      link: "/admin/festivos",
      key: 18,
    },
    {
      name: "menu.permissions",
      icon: <SolutionOutlined />,
      link: "/admin/permissions",
      key: 8,
    },
    
    {
      name: "menu.timesheet",
      icon: <FileExcelOutlined />,
      link: "/admin/timesheet",
      key: 5,
    },

    
  ];

  const currentRoute = menuItems.find((link) =>
    matchPath(rest.location.pathname, link)
  );

  const getCurrentRoute = () => {
    var ret = ["1"];

    menuItems.map((item) => {
      if (rest.location.pathname.includes(item.link)) {
        ret = ["" + item.key + ""];
      }
    });

    console.log(4, ret);
    return ret;
  };

  return (
    <>
       <Layout className="layout-page">
       <HeaderLayout  collapsed={collapsed} toggle={toggle} />
        

        <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            setCollapsed(collapsed);
            console.log(collapsed, type);
          }}
        >

          <Menu selectedKeys={getCurrentRoute()} theme="dark" mode="inline">
            {menuItems.map((menuItem) => {
              return (
                <Menu.Item key={menuItem.key} icon={menuItem.icon}>
                  <Link to={menuItem.link}>{t(menuItem.name)}</Link>
                </Menu.Item>
              );
            })}
          </Menu>
        </Sider>

          <Content className="layout-page-content">
            {switchRoutes}
          </Content>
        
        </Layout>
        </Layout>
    </>
  );
}
export default withTranslation()(Admin);
