import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { festivosActions, employeeActions } from "_actions/";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { history } from "_helpers/history";
import * as Yup from "yup";
import * as _ from "lodash";
import { DatePicker } from "antd";
import moment from "moment";

import { service } from "_services/service";
import {
  Form,
  Space,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  InputNumber,
  Col,
  Checkbox,
  Button,
  message,
  AutoComplete,
} from "antd";

const { Option } = Select;
const { RangePicker } = DatePicker;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function AddPermission(props) {
  const { path } = props.match;
  const { id } = props.match.params;
  const { t, i18n } = props;
  const isAddMode = !id;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [festivo, setFestivo] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [employeesOrigin, setEmployeesOrigin] = useState([]);
  const [checked, setChecked] = useState(true);
  const [horas, setHoras] = useState([]);
  const [ausencia, setAusencia] = useState(false);
  const [isHollidays, setIsHollidays] = useState(false);

  const [vacaciones, setVacaciones] = useState(false);

  var employeesList = useSelector(
    (state) => state.employeeReducer.employeesList
  );
  var festivoData = useSelector((state) => state.festivoReducer.festivoData);

  var newFestivoData = useSelector((state) => state.festivoReducer.newFestivoData);

  var saveFestivoData = useSelector((state) => state.festivoReducer.saveFestivoData);

  var newFestivoDataError = useSelector(
    (state) => state.festivoReducer.newFestivoDataError
  );

  var saveFestivoDataError = useSelector(
    (state) => state.festivoReducer.saveFestivoDataError
  );


  useEffect(() => {
    if (employeesList) {
      setEmployees(employeesList);
      setEmployeesOrigin(employeesList);
    }
  }, [employeesList]);

  const onCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const getEmployees = () => {
    dispatch(employeeActions.getEmployees());
  };

  useEffect(() => {
    setFestivo(festivoData);
    console.log(festivoData);

    const { setFieldsValue, getFieldValue } = form;

    if (festivoData) {
      setChecked(festivoData.active);
    }

    setFieldsValue(festivoData);
  }, [festivoData]);

  useEffect(() => {
    if (newFestivoDataError) message.error(newFestivoDataError.msg);

    if (saveFestivoDataError) message.error(saveFestivoDataError.msg);
  }, [newFestivoDataError, saveFestivoDataError]);

  useEffect(() => {
    if (newFestivoData || saveFestivoData) message.success(t("saved"));

    if (saveFestivoData) history.push("..");

    if (newFestivoData) history.push(".");
  }, [newFestivoData, saveFestivoData]);

  useEffect(() => {
    if (!isAddMode) {
      // get festivo and set form fields
      dispatch(festivosActions.getFestivo(id));
    } else {
      const { setFieldsValue, getFieldValue } = form;

      setFieldsValue(festivoData);
    }
  }, []);

  const onFinish = (data) => {
    return isAddMode ? create(data) : create( data);
  };



  function changeAusencia(value) {
    setAusencia(value);
    setVacaciones(false);
  }
  function changeVacaciones(value) {
    setAusencia(false);
    setVacaciones(value);
  }


  function onSubmit(data) {}

  function create(data) {
  
    console.log(data)
    if (data.from.isAfter(data.to)) {
      message.error(t("permissions.errorF"));
    } else {
      
      const payload = {
        userId: data.userId,
        initDate: moment(data.from).format("YYYY-MM-DD"),
        endDate: moment(data.to).format("YYYY-MM-DD"),
        typepermissionId: 1,
      };
      console.log(payload);
      service.postToken("/v1/user/permission", payload).then((response) => {
        console.log(response)
        history.push(".")
      });


    }
  }

  const handleSearch = (value) => {
    if (value) {
      const filterTable = employees.filter((item) => {
        console.log(88, item);
        if (!item) {
          return false;
        }
        return (
          item &&
          item.name &&
          item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
        );
      });

      setEmployees(filterTable);
    } else {
      setEmployees(employeesOrigin);
    }
  };

  const options = employees.map((d) => {
    if (d.active) {
      return (
        <Option value={d.id} key={d.id}>
          {d.name} {d.surname}
        </Option>
      );
    }
  });


  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }

  return (
    <div style={{ padding: 10 }}>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={{
          ["sessionslimit"]: "0",
        }}
      >
        <Form.Item
          name="userId"
          label={t("guards.employee")}
          hasFeedback
          rules={[{ required: true, message: t("required") }]}
        >
          <Select
          
            placeholder={t("guards.employee")}
            showSearch={true}
            defaultActiveFirstOption={true}
            filterOption={false}
            onSearch={handleSearch}
            notFoundContent={null}
          >
            {options}
          </Select>
        </Form.Item>
        <Form.Item name="from" label={t("permissions.from")}>
            <DatePicker  disabledDate={disabledDate} format="DD/MM/YYYY" />
          </Form.Item>

          <Form.Item name="to" label={t("permissions.to")}>
            <DatePicker   disabledDate={disabledDate} format="DD/MM/YYYY" />
          </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            {t("save")}
          </Button>

          <Link to={isAddMode ? "." : ".."}>
            <Button>{t("cancel")}</Button>
          </Link>
        </Form.Item>
      </Form>
    </div>
  );
}
export default withTranslation()(AddPermission);
