import actionTypes from "_constants/actionTypes";
const initialState = { loading: false, queue: 0, APIactions: 0 };

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardDataError: null,
        dashboardData: action.payload,
      };
    case actionTypes.GET_DASHBOARD_ERROR:
      return {
        ...state,
        dashboardData: null,
        dashboardDataError: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};
