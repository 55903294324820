
import actionTypes from "_constants/actionTypes";

export const timesheetActions = {
    getTimesheet,
  endTransaction
};
function endTransaction() {
  return {
    type: actionTypes.END_TRANSACTION,
  };
}
function getTimesheet(month, year) {
  return {
    type: actionTypes.GET_TIMESHEET,
    payload: {month, year},
  };
}
