import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "_constants/actionTypes";
import { service } from "../_services/service";
import { history } from "_helpers/history";
import config from "_config/config";
export default function* watcherUser() {

  yield takeEvery(actionTypes.UPDATE_USER_AUTH, updateUser);
  yield takeEvery(actionTypes.UPDATE_PWD_AUTH, updatePwd);
}


function* updatePwd({payload}) {
  yield put({ type: actionTypes.START_REQUEST });
  
  try {
    let apiEndpoint = "/v1/me/password";
    console.log(payload);
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.putToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.UPDATE_PWD_AUTH_DATA,
        payload: res.data,
      });
    } else {
      console.log(res);
      yield put({
        type: actionTypes.UPDATE_PWD_AUTH_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.UPDATE_PWD_AUTH_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}


function* updateUser({payload}) {
  yield put({ type: actionTypes.START_REQUEST });
  
  try {
    let apiEndpoint = "/v1/me";
    console.log(payload);
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.putToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.UPDATE_USER_AUTH_DATA,
        payload: res.data,
      });
    } else {
      console.log(res);
      yield put({
        type: actionTypes.UPDATE_USER_AUTH_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.UPDATE_USER_AUTH_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}


