import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { employeeActions } from "_actions/";
import { Table, Tag, Space, Button, message, Pagination } from "antd";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";

import {
  PlusCircleOutlined,UserSwitchOutlined,
  EditFilled,
  MailOutlined,
} from "@ant-design/icons";
function ListEmployee(props) {
  const { t, i18n } = props;
  const { path } = props.match;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  var employeesList = useSelector(
    (state) => state.employeeReducer.employeesList
  );
  var employeesListError = useSelector(
    (state) => state.employeeReducer.employeesListError
  );

  var resendEmployeeData = useSelector(
    (state) => state.employeeReducer.resendEmployeeData
  );
  var resendEmployeeDataError = useSelector(
    (state) => state.employeeReducer.resendEmployeeDataError
  );
  useEffect(() => {
    if (resendEmployeeData) message.success(t("sended"));
    if (resendEmployeeDataError) message.success(t("Error"));
  }, [resendEmployeeData, resendEmployeeDataError]);

  useEffect(() => {
    getEmployees();
  }, []);

  useEffect(() => {
    setLoading(false);
    if (employeesList) {
    }
    if (employeesListError) {
    }
  }, [employeesList, employeesListError]);

  const reSend = (item) => {
    dispatch(employeeActions.resend(item.id));
  };

  const getEmployees = () => {
    dispatch(employeeActions.getEmployees());
  };

  const columns = [
    {
      title: t("employee.name"),
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <span>{record.name + " " + record.surname}</span>
      ),
    },
    {
      title: t("employee.email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("employee.nif"),
      dataIndex: "nif",
      key: "nif",
    },

    {
      title: t("employee.center"),
      dataIndex: "center",
      key: "center",
      render: (text, record) => <span>{record.workcenter.name}</span>,
    },

    {
      title: t("status"),
      key: "actionaa",
      render: (text, record) => (
        <div className={`dot ${record.active ? `isactive` : `isdeactive`} `}></div>
      ),
    },
    {
      title: t("actions"),
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Link onClick={() => reSend(record)}>
            <MailOutlined />
          </Link>
          <Link to={`${path}/edit/${record.id}`}>
            <EditFilled />
          </Link>
         
        </Space>
      ),
    },
  ];

  const cancel = () => {};

  return (
    <div style={{ padding: 10 }}>
      <h1>{t("menu.employees")}</h1>
      <Link to={`${path}/add`} className="btn btn-sm btn-success mb-2">
        <Button type="primary" icon={<PlusCircleOutlined />}>
          {t("employee.new")}
        </Button>
      </Link>

      <Table columns={columns} dataSource={employeesList} />
    </div>
  );
}
export default withTranslation()(ListEmployee);
