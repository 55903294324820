import React from 'react';
import { Route, Switch } from 'react-router-dom';

import  ListGuard from './list';
import  EditGuard  from './edit';

function Guard({ match }) {
    const { path } = match;
    console.log(999,"hola");
    return (
        <Switch>
            <Route exact path={path} component={ListGuard} />
            <Route path={`${path}/add`} component={EditGuard} />
        </Switch>
    );
}

export { Guard };