import { combineReducers } from "redux";
import loading from "./loading.reducer";
import authReducer from "./auth.reducer";
import employeeReducer from "./employee.reducer";
import dashboardReducer from "./dashboard.reducer";
import workcenterReducer from "./workcenter.reducer";
import guardReducer from "./guards.reducer";
import timesheetReducer from "./timesheet.reducer";
import festivoReducer from "./festivo.reducer";

import userReducer from "./user.reducer";
const rootReducer = combineReducers({
  loading,
  authReducer,
  employeeReducer,festivoReducer,
  workcenterReducer,
  dashboardReducer,
  guardReducer,userReducer,
  timesheetReducer
});

export default rootReducer;
