import { all } from "redux-saga/effects";
import watcherCounter from "./counter.saga";
import watcherAuthentication from "./auth.saga";
import watcherEmployees from "./employee.saga";
import watcherDashboard from "./dashboard.saga";
import watcherWorkcenters from "./workcenter.saga";
import watcherGuards from './guards.saga';
import watcherTimesheet from "./timesheet.saga";
import watcherFestivos from "./festivo.saga";
import watcherUser from "./user.saga";
export default function* rootSaga() {
  yield all([
    watcherCounter(),
    watcherAuthentication(),watcherFestivos(),
    watcherWorkcenters(),
    watcherEmployees(),
    watcherDashboard(),
    watcherGuards(),
    watcherTimesheet(),
    watcherUser()
  ]);
}
