import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { workcenterActions } from "_actions/";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { history } from "_helpers/history";
import * as Yup from "yup";
import * as _ from "lodash";
import {
  Form,
  Space,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  InputNumber,
  Col,
  Checkbox,
  Button,
  message,
  AutoComplete,
} from "antd";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function EditWorkcenter(props) {
  const { path } = props.match;
  const { id } = props.match.params;
  const { t, i18n } = props;
  const isAddMode = !id;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [workcenter, setWorkcenter] = useState(null);
  const [centers, setCenters] = useState([]);
  const [centersOrigin, setCentersOrigin] = useState([]);
  const [checked, setChecked] = useState(true);
  var workcenterData = useSelector((state) => state.workcenterReducer.workcenterData);

  var newWorkcenterData = useSelector((state) => state.workcenterReducer.newWorkcenterData);

  var saveWorkcenterData = useSelector((state) => state.workcenterReducer.saveWorkcenterData);

  var newWorkcenterDataError = useSelector(
    (state) => state.workcenterReducer.newWorkcenterDataError
  );

  var saveWorkcenterDataError = useSelector(
    (state) => state.workcenterReducer.saveWorkcenterDataError
  );

  const onCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  useEffect(() => {
    setWorkcenter(workcenterData);
    console.log(workcenterData);

    const { setFieldsValue, getFieldValue } = form;

    if (workcenterData) {
      setChecked(workcenterData.active);
    }

    setFieldsValue(workcenterData);
  }, [workcenterData]);

  useEffect(() => {
    if (newWorkcenterDataError) message.error(newWorkcenterDataError.msg);

    if (saveWorkcenterDataError) message.error(saveWorkcenterDataError.msg);
  }, [newWorkcenterDataError, saveWorkcenterDataError]);

  useEffect(() => {
    if (newWorkcenterData || saveWorkcenterData) message.success(t("saved"));

    if (saveWorkcenterData) history.push("..");

    if (newWorkcenterData) history.push(".");
  }, [newWorkcenterData, saveWorkcenterData]);

  useEffect(() => {
    if (!isAddMode) {
      // get workcenter and set form fields
      dispatch(workcenterActions.getWorkcenter(id));
    } else {
      const { setFieldsValue, getFieldValue } = form;

      setFieldsValue(workcenterData);
    }
  }, []);

  const onFinish = (data) => {
    return isAddMode ? create(data) : update(id, data);
  };

  function onSubmit(data) {}

  function create(data) {
    console.log(222, data);
    var comp = centersOrigin.filter(
      (item) => "" + item.id === "" + data.centerId
    );
    console.log(111, centersOrigin);
    console.log(222, comp);
    if (comp && comp[0]) {
      data.center = comp[0].name;
      data.centerIdHubspot = comp[0].idHubSpot;
      data.centerDomain = comp[0].subdomain;
    }
    data.active = true;
    dispatch(workcenterActions.newWorkcenter(data));
  }

  function update(id, data) {
    data.id = id;
    data.active = checked;
    console.log(data);
    dispatch(workcenterActions.updateWorkcenter(data));
  }

  const handleSearch = (value) => {
    if (value) {
      const filterTable = centers.filter((item) => {
        console.log(88, item);
        if (!item) {
          return false;
        }
        return (
          item &&
          item.name &&
          item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
        );
      });

      setCenters(filterTable);
    } else {
      setCenters(centersOrigin);
    }
  };

  const options = centers.map((d) => {
    if (d.active) {
      return (
        <Option value={d.id} key={d.id}>
          {d.name}
        </Option>
      );
    }
  });

  return (
    <div style={{ padding: 10 }}>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={{
          ["sessionslimit"]: "0",
        }}
      >
        <Form.Item
          name="name"
          label={t("centers.name")}
          rules={[
            {
              required: true,
              message: t("required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label={t("centers.description")}
          rules={[
            {
              required: true,
              message: t("required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      
       
        <Form.Item valuePropName="active" name="active" label={t("active")}>
          <Checkbox checked={checked} onChange={onCheckboxChange} />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            {t("save")}
          </Button>

          <Link to={isAddMode ? "." : ".."}>
            <Button>{t("cancel")}</Button>
          </Link>
        </Form.Item>
      </Form>
    </div>
  );
}
export default withTranslation()(EditWorkcenter);
