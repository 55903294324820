import actionTypes from "_constants/actionTypes";

export const employeeActions = {
  getEmployees,
  endTransaction,
  newEmployee,
  updateEmployee,
  getEmployee,getPermissions, updatePermission,delPermission,
  resend
};


function delPermission(payload) {
  return {
    type: actionTypes.DEL_PERMISSIONS,
    payload,
  };
}


function updatePermission(payload) {
  return {
    type: actionTypes.SAVE_PERMISSIONS,
    payload,
  };
}

function getPermissions() {
  const payload = "";
  return {
    type: actionTypes.GET_PERMISSIONS,
    payload,
  };
}
function endTransaction() {
  return {
    type: actionTypes.END_TRANSACTION,
  };
}

function getEmployees() {
  return {
    type: actionTypes.GET_EMPLOYEES,
    payload: "",
  };
}

function newEmployee(payload) {
  
  return {
    type: actionTypes.NEW_EMPLOYEE,
    payload: payload,
  };
}

function updateEmployee(payload) {
  return {
    type: actionTypes.SAVE_EMPLOYEE,
    payload: payload,
  };
}

function getEmployee(id) {
  return {
    type: actionTypes.GET_EMPLOYEE,
    payload: id,
  };
}

function resend(id) {
  return {
    type: actionTypes.RESEND_EMPLOYEE,
    payload: id,
  };
}
