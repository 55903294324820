import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { festivosActions } from "_actions/";
import { Table, Tag, Space, Button, message, Pagination, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import {
  PlusCircleOutlined,DeleteOutlined,
  EditFilled,
  MailOutlined,
} from "@ant-design/icons";
function ListFestivo(props) {
  const { t, i18n } = props;
  const { path } = props.match;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  var festivosList = useSelector((state) => state.festivoReducer.festivosList);
  var festivosListError = useSelector((state) => state.festivoReducer.festivosListError);

  var saveFestivoData = useSelector((state) => state.festivoReducer.saveFestivoData);
  var saveFestivoDataError = useSelector(
    (state) => state.festivoReducer.saveFestivoDataError
  );
  useEffect(() => {
    getFestivos();
  }, [saveFestivoData, saveFestivoDataError]);


  useEffect(() => {
  

    getFestivos();
  }, []);

  useEffect(() => {
    setLoading(false);
    if (festivosList) {
    }
    if (festivosListError) {
    }
  }, [festivosList, festivosListError]);


  const getFestivos = () => {
    dispatch(festivosActions.getFestivos());
  };


  const columns = [
    {
      title: t("festivos.name"),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <span>{record.name}</span>
       ),
    },
    {
      title: t("festivos.date"),
      dataIndex: "initDate",
      key: "initDate",
      render: (text, record) => (
        <span>{moment(record.initDate).format("DD/MM/YYYY")}</span>
      ),
    },
   
    {
      title: t("actions"),
      key: 'action',
      render: (text, record) => (
        
        <Space size="middle">
         
         <Popconfirm
              title="Are you sure to reject this holiday?"
              onConfirm={() => deleItem(record)}
              onCancel={null}
              okText="Yes"
              cancelText="No"
            ><DeleteOutlined style={{cursor: "pointer"}} /></Popconfirm>
          
        </Space>
      ),
    },
  ];

  const deleItem = (record) => {

    dispatch(festivosActions.updateFestivo(record))
    
  }
  const cancel = () => {};

  return (
    <div style={{ padding: 10 }}>
      <h1>{t("menu.festivos")}</h1>
      <Link to={`${path}/add`} className="btn btn-sm btn-success mb-2">
        <Button type="primary" icon={<PlusCircleOutlined />}>
          {t("festivos.new")}
        </Button>
      </Link>

      <Table columns={columns} dataSource={festivosList} />

    </div>
  );
}
export default withTranslation()(ListFestivo);
