import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "_constants/actionTypes";
import { service } from "../_services/service";
import { history } from "_helpers/history";
export default function* watcherGuards() {
    yield takeEvery(actionTypes.GET_GUARD, getGuard);
    yield takeEvery(actionTypes.GET_GUARDS, getGuards);
    yield takeEvery(actionTypes.SAVE_GUARD, saveGuard);
    yield takeEvery(actionTypes.NEW_GUARD, newGuard);
    
}



function* getGuard(payload) {
   yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/guards/guard/" + payload.payload;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.getToken(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.GET_GUARD_DATA,
        payload: res.data,
      });
      yield put({
        type: actionTypes.CLEAR_DATA_GUARD,
        payload: null,
      });


    } else {
      yield put({
        type: actionTypes.GET_GUARD_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GET_GUARD_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* saveGuard({payload}) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/guards/guard/" + payload.id;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.deleteToken(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.SAVE_GUARD_DATA,
        payload: res.data,
      });


      yield put({
        type: actionTypes.CLEAR_DATA_GUARD,
        payload: null,
      });
      
    } else {
      yield put({
        type: actionTypes.SAVE_GUARD_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.SAVE_GUARD_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}


function* newGuard({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/guards/guard";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.postToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.NEW_GUARD_DATA,
        payload: res.data,
      });

      yield put({
        type: actionTypes.CLEAR_DATA_GUARD,
        payload: null,
      });

    } else {
      yield put({
        type: actionTypes.NEW_GUARD_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.NEW_GUARD_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}


function* getGuards() {
    yield put({ type: actionTypes.START_REQUEST });
   
    try {
      let apiEndpoint = "/v1/guards";
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            service.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res) {
        if (res.status && res.status === 200 ) {
          yield put({ type: actionTypes.GET_GUARDS_DATA, payload: res.data });
        } else {
          yield put({ type: actionTypes.GET_GUARDS_ERROR, payload: "Error" });
        }
      } else {
        yield put({
          type: actionTypes.GET_GUARDS_ERROR,
          payload: "Error",
        });
      }
    } catch (e) {
      yield put({
        type: actionTypes.GET_GUARDS_ERROR,
        payload: "Error",
      });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }