import actionTypes from "_constants/actionTypes";

export const userActions = {
 
  endTransaction,updateUser,updatePwd
};

function updatePwd(payload) {
  return {
    type: actionTypes.UPDATE_PWD_AUTH,
    payload: payload,
  };
}



function endTransaction() {
  return {
    type: actionTypes.END_TRANSACTION,
  };
}

function updateUser(payload) {
  return {
    type: actionTypes.UPDATE_USER_AUTH,
    payload: payload,
  };
}
