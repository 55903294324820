import React from "react";
import { Route, Redirect } from "react-router-dom";
import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    component={(props) => {
      var token = (localStorage.getItem("orionBKToken"));
    

      if (!token ) {
        return <Redirect to="/auth/login" />;
      } else {
        var path = props.location.pathname;

    
        if (path.includes("/admin")) {
          return <Admin {...props} />;
        }
      }

      return (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      );
    }}
  />
);
