import React, { useState, useEffect } from "react";
import { Col, Row, Tooltip } from "antd";
import moment from "moment";
import {dashboardActions} from "_actions/";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  SolutionOutlined,
  PieChartOutlined,
  SmileOutlined,
  TeamOutlined,
  DeploymentUnitOutlined,
} from "@ant-design/icons";
import "./index.scss";
function DashboardPage(props) {
  const { t, i18n } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  var dashboardData = useSelector((state) => state.dashboardReducer.dashboardData);
  
  useEffect(() => {
    getDashboard();

  }, []);


  const getDashboard = () => {
  
    dispatch(dashboardActions.getDashboard())
  }


  return (
    <section className="dashboard-wrapper">
      <Row className="row-top">
        <Col className="wrapper" lg={6} md={6} sm={12} xl={12} xs={12}>
          <Tooltip mouseEnterDelay={0.5} title={t("menu.users")}>
            <div className="block-wrapper">
              <TeamOutlined className="star" />
              <span>{dashboardData ? dashboardData.user : ""}</span>
            </div>
          </Tooltip>
        </Col>
        <Col className="wrapper" lg={6} md={6} sm={12} xl={12} xs={12}>
          <Tooltip mouseEnterDelay={0.5} title={t("menu.companies")}>
            <div className="block-wrapper">
              <SolutionOutlined className="eye" />
              <span>{dashboardData ? dashboardData.company : ""}</span>
            </div>
          </Tooltip>
        </Col>
        <Col className="wrapper" lg={6} md={6} sm={12} xl={12} xs={12}>
          <Tooltip mouseEnterDelay={0.5} title={t("menu.coaches")}>
            <div className="block-wrapper">
              <SmileOutlined className="save" />
              <span>{dashboardData ? dashboardData.coach : ""}</span>
            </div>
          </Tooltip>
        </Col>
        <Col className="wrapper" lg={6} md={6} sm={12} xl={12} xs={12}>
          <Tooltip mouseEnterDelay={0.5} title={t("menu.skills")}>
            <div className="block-wrapper">
              <DeploymentUnitOutlined className="message" />
              <span>{dashboardData ? dashboardData.skills : ""}</span>
            </div>
          </Tooltip>
        </Col>
      </Row>
    </section>
  );
}
export default withTranslation()(DashboardPage);
