import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { festivosActions, employeeActions } from "_actions/";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { history } from "_helpers/history";
import * as Yup from "yup";
import * as _ from "lodash";
import { DatePicker } from "antd";
import moment from "moment";

import {
  Form,
  Space,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  InputNumber,
  Col,
  Checkbox,
  Button,
  message,
  AutoComplete,
} from "antd";

const { Option } = Select;
const { RangePicker } = DatePicker;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function EditFestivo(props) {
  const { path } = props.match;
  const { id } = props.match.params;
  const { t, i18n } = props;
  const isAddMode = !id;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [festivo, setFestivo] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [employeesOrigin, setEmployeesOrigin] = useState([]);
  const [checked, setChecked] = useState(true);
  var employeesList = useSelector(
    (state) => state.employeeReducer.employeesList
  );
  var festivoData = useSelector((state) => state.festivoReducer.festivoData);

  var newFestivoData = useSelector((state) => state.festivoReducer.newFestivoData);

  var saveFestivoData = useSelector((state) => state.festivoReducer.saveFestivoData);

  var newFestivoDataError = useSelector(
    (state) => state.festivoReducer.newFestivoDataError
  );

  var saveFestivoDataError = useSelector(
    (state) => state.festivoReducer.saveFestivoDataError
  );


  useEffect(() => {
    if (employeesList) {
      setEmployees(employeesList);
      setEmployeesOrigin(employeesList);
    }
  }, [employeesList]);

  const onCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const getEmployees = () => {
    dispatch(employeeActions.getEmployees());
  };

  useEffect(() => {
    setFestivo(festivoData);
    console.log(festivoData);

    const { setFieldsValue, getFieldValue } = form;

    if (festivoData) {
      setChecked(festivoData.active);
    }

    setFieldsValue(festivoData);
  }, [festivoData]);

  useEffect(() => {
    if (newFestivoDataError) message.error(newFestivoDataError.msg);

    if (saveFestivoDataError) message.error(saveFestivoDataError.msg);
  }, [newFestivoDataError, saveFestivoDataError]);

  useEffect(() => {
    if (newFestivoData || saveFestivoData) message.success(t("saved"));

    if (saveFestivoData) history.push("..");

    if (newFestivoData) history.push(".");
  }, [newFestivoData, saveFestivoData]);

  useEffect(() => {
    if (!isAddMode) {
      // get festivo and set form fields
      dispatch(festivosActions.getFestivo(id));
    } else {
      const { setFieldsValue, getFieldValue } = form;

      setFieldsValue(festivoData);
    }
  }, []);

  const onFinish = (data) => {
    return isAddMode ? create(data) : update(id, data);
  };

  function onSubmit(data) {}

  function create(data) {
  data.active = true;
  data.initDate = moment(data.initDate).format("YYYY-MM-DD")
    dispatch(festivosActions.newFestivo(data));
  }

  function update(id, data) {
    data.id = id;
    data.active = checked;
    data.initDate = moment(data.initDate).format("YYYY-MM-DD")
    console.log(data);
    dispatch(festivosActions.updateFestivo(data));
  }

  const handleSearch = (value) => {
    if (value) {
      const filterTable = employees.filter((item) => {
        console.log(88, item);
        if (!item) {
          return false;
        }
        return (
          item &&
          item.name &&
          item.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
        );
      });

      setEmployees(filterTable);
    } else {
      setEmployees(employeesOrigin);
    }
  };

  const options = employees.map((d) => {
    if (d.active) {
      return (
        <Option value={d.id} key={d.id}>
          {d.name} {d.surname}
        </Option>
      );
    }
  });

  return (
    <div style={{ padding: 10 }}>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={{
          ["sessionslimit"]: "0",
        }}
      >
        <Form.Item
        name="name"
        label={t("employee.name")}
        rules={[
          {
            required: true,
            message: t("required"),
          },
        ]}
      >
        <Input />
      </Form.Item>
        <Form.Item
          name="initDate"
          label={t("festivos.date")}
          rules={[
            {
              required: true,
              message: t("required"),
            },
          ]}
        >
          <DatePicker format="YYYY-MM-DD" />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            {t("save")}
          </Button>

          <Link to={isAddMode ? "." : ".."}>
            <Button>{t("cancel")}</Button>
          </Link>
        </Form.Item>
      </Form>
    </div>
  );
}
export default withTranslation()(EditFestivo);
