import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { guardsActions } from "_actions/";
import { Table, Tag, Space, Button, message, Pagination } from "antd";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import {
  PlusCircleOutlined,DeleteOutlined,
  EditFilled,
  MailOutlined,
} from "@ant-design/icons";
function ListGuard(props) {
  const { t, i18n } = props;
  const { path } = props.match;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  var guardsList = useSelector((state) => state.guardReducer.guardsList);
  var guardsListError = useSelector((state) => state.guardReducer.guardsListError);

  var saveGuardData = useSelector((state) => state.guardReducer.saveGuardData);
  var saveGuardDataError = useSelector(
    (state) => state.guardReducer.saveGuardDataError
  );
  useEffect(() => {
    getGuards();
  }, [saveGuardData, saveGuardDataError]);


  useEffect(() => {
  

    getGuards();
  }, []);

  useEffect(() => {
    setLoading(false);
    if (guardsList) {
    }
    if (guardsListError) {
    }
  }, [guardsList, guardsListError]);


  const getGuards = () => {
    dispatch(guardsActions.getGuards());
  };


  const columns = [
    {
      title: t("guards.datei"),
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => moment(a.date) - moment(b.date),
      render: (text, record) => (
        <span>{moment(record.date).format("DD/MM/YYYY")}</span>
       ),
    },
    {
      title: t("guards.dateTo"),
      dataIndex: 'dateTo',
      key: 'dateTo',
      sorter: (a, b) => moment(a.dateTo) - moment(b.dateTo),
      render: (text, record) => (
        <span>{moment(record.dateTo).format("DD/MM/YYYY")}</span>
       ),
    },
    {
      title: t("guards.employee"),
      key: "employee",
      render: (text, record) => (
       <span>{record.user.name} {record.user.surname}</span>
      ),
    },

   
    {
      title: t("actions"),
      key: 'action',
      render: (text, record) => (
        
        <Space size="middle">
          {moment(record.date) > moment() && 
                <DeleteOutlined style={{cursor: "pointer"}} onClick={() => deleItem(record)} />
          }
        </Space>
      ),
    },
  ];

  const deleItem = (record) => {

    dispatch(guardsActions.updateGuard(record))
    
  }
  const cancel = () => {};

  return (
    <div style={{ padding: 10 }}>
      <h1>{t("menu.guards")}</h1>
      <Link to={`${path}/add`} className="btn btn-sm btn-success mb-2">
        <Button type="primary" icon={<PlusCircleOutlined />}>
          {t("guards.new")}
        </Button>
      </Link>

      <Table columns={columns} dataSource={guardsList} />

    </div>
  );
}
export default withTranslation()(ListGuard);
