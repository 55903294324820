import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "_constants/actionTypes";
import { service } from "../_services/service";
import { history } from "_helpers/history";
export default function* watcherTimesheet() {
    yield takeEvery(actionTypes.GET_TIMESHEET, getTimesheet);
    
}


function* getTimesheet({payload}) {
   yield put({ type: actionTypes.START_REQUEST });

   console.log(payload);
  try {
    let apiEndpoint = "/v1/timesheet/" + payload.year + "/" + payload.month ;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.getToken(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.GET_TIMESHEET_DATA,
        payload: res.data,
      });
     
      yield put({
        type: actionTypes.GET_CLEAR_TIMESHEET_DATA,
        payload: res.data,
      });

    } else {
      yield put({
        type: actionTypes.GET_TIMESHEET_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GET_TIMESHEET_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
