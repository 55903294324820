import actionTypes from "_constants/actionTypes";

export const guardsActions = {
  getGuards,
  endTransaction,
  newGuard,
  updateGuard,
  getGuard,
  
};
function endTransaction() {
  return {
    type: actionTypes.END_TRANSACTION,
  };
}

function getGuards() {
  return {
    type: actionTypes.GET_GUARDS,
    payload: "",
  };
}

function newGuard(payload) {
  
  return {
    type: actionTypes.NEW_GUARD,
    payload: payload,
  };
}

function updateGuard(payload) {
  return {
    type: actionTypes.SAVE_GUARD,
    payload: payload,
  };
}

function getGuard(id) {
  return {
    type: actionTypes.GET_GUARD,
    payload: id,
  };
}

