import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import store from "./store.js";
import { history } from "_helpers/history";
import { PrivateRoute } from "_helpers/privateRoute";
import { Provider } from "react-redux";
import Admin from 'layouts/Admin';
import Auth from 'layouts/Auth';
import "antd/dist/antd.css";
import "assets/sass/normal.scss";


const Loader = () => {
  return <div>loading...</div>;
};
ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Loader />}>
      <Router history={history}>
      <Switch>
          <Route path="/auth" component={Auth} />
          <PrivateRoute path="/admin" component={Admin} />
          <Redirect from="/" to="/auth/login" />
        </Switch>
      </Router>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);


reportWebVitals();
