import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { timesheetActions,festivosActions } from "_actions/";
import { v1 as uuidv1 } from "uuid";
import { Spin } from "antd";

import { service } from "_services/service";
import {
  PDFDownloadLink,
  Page,
  Text,
  Document,
  StyleSheet,
  usePDF,View
} from "@react-pdf/renderer";

import {
  Table,
  Tag,
  Space,
  Button,
  message,
  Pagination,
  DatePicker,
} from "antd";
import { Link } from "react-router-dom";
import * as _ from "lodash";
import moment from "moment";

import { DownloadOutlined,PlusCircleOutlined } from "@ant-design/icons";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const MyDoc = (record, month, year, festivos) => {
  const dataUser = record;

  const mUser = dataUser[0].user;
  const mCompany = dataUser[0].company;
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap={false}>
        <View>
          <Text
            style={{
              marginBottom: 2,
              marginTop: 10,
              fontSize: 15,
              fontWeight: 700,

              textAlign: "center",
            }}
          >
            Listado Resumen mensual del registro de jornada
          </Text>

          {/**  Inicio Fila 1 **/}
          <View style={{ flexDirection: "row", margin: "auto" }}>
            <View
              style={{
                width: "47%",
                backgroundColor: "#E4E4E4",
                padding: 2,
                fontSize: 8,
                fontWeight: 700,
                marginLeft: 0,
                color: "#000000",
                textAlign: "left",
                lineHeight: 1,
                borderStyle: "solid",
                borderColor: "#000000",
                borderWidth: 1,
                borderLeftWidth: 1,
                borderTopWidth: 1,
              }}
            >
              <Text style={{ margin: 2 }}>Empresa: {mCompany.name}</Text>
            </View>
            <View
              style={{
                width: "47%",
                backgroundColor: "#E4E4E4",
                padding: 2,
                fontSize: 8,
                fontWeight: 700,
                marginLeft: 0,
                color: "#000000",
                textAlign: "left",
                lineHeight: 1,
                borderStyle: "solid",
                borderColor: "#000000",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 1,
              }}
            >
              <Text style={{ margin: 2 }}>
                Trabajador: {mUser.name + " " + mUser.surname}
              </Text>
            </View>
          </View>
          {/**  Fin Fila 1 **/}

          {/**  Inicio Fila 2 **/}
          <View style={{ flexDirection: "row", margin: "auto" }}>
            <View
              style={{
                width: "47%",
                backgroundColor: "#E4E4E4",
                padding: 2,
                fontSize: 8,
                fontWeight: 700,
                marginLeft: 0,
                color: "#000000",
                textAlign: "left",
                lineHeight: 1,
                borderStyle: "solid",
                borderColor: "#000000",
                borderWidth: 1,
                borderLeftWidth: 1,
                borderTopWidth: 0,
              }}
            >
              <Text style={{ margin: 2 }}>C.I.F/N.I.F.: {mCompany.cif}</Text>
            </View>
            <View
              style={{
                width: "47%",
                backgroundColor: "#E4E4E4",
                padding: 2,
                fontSize: 8,
                fontWeight: 700,
                marginLeft: 0,
                color: "#000000",
                textAlign: "left",
                lineHeight: 1,
                borderStyle: "solid",
                borderColor: "#000000",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
              }}
            >
              <Text style={{ margin: 2 }}>N.I.F: {mUser.nif}</Text>
            </View>
          </View>
          {/**  Fin Fila 2 **/}

          {/**  Inicio Fila 3 **/}
          <View style={{ flexDirection: "row", margin: "auto" }}>
            <View
              style={{
                width: "47%",
                backgroundColor: "#E4E4E4",
                padding: 2,
                fontSize: 8,
                fontWeight: 700,
                marginLeft: 0,
                color: "#000000",
                textAlign: "left",
                lineHeight: 1,
                borderStyle: "solid",
                borderColor: "#000000",
                borderWidth: 1,
                borderLeftWidth: 1,
                borderTopWidth: 0,
              }}
            >
              <Text style={{ margin: 2 }}>
                Centro de trabajo: {mUser.workcenter.name}
              </Text>
            </View>
            <View
              style={{
                width: "47%",
                backgroundColor: "#E4E4E4",
                padding: 2,
                fontSize: 8,
                fontWeight: 700,
                marginLeft: 0,
                color: "#000000",
                textAlign: "left",
                lineHeight: 1,
                borderStyle: "solid",
                borderColor: "#000000",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
              }}
            >
              <Text style={{ margin: 2 }}>
                Num. Afilicación: {mUser.afiliation}
              </Text>
            </View>
          </View>
          {/**  Fin Fila 3 **/}

          {/**  Inicio Fila 4 **/}
          <View style={{ flexDirection: "row", margin: "auto" }}>
            <View
              style={{
                width: "47%",
                backgroundColor: "#E4E4E4",
                padding: 2,
                fontSize: 8,
                fontWeight: 700,
                marginLeft: 0,
                color: "#000000",
                textAlign: "left",
                lineHeight: 1,
                borderStyle: "solid",
                borderColor: "#000000",
                borderWidth: 1,
                borderLeftWidth: 1,
                borderTopWidth: 0,
              }}
            >
              <Text style={{ margin: 2 }}>C.C.C.: {mCompany.numc}</Text>
            </View>
            <View
              style={{
                width: "47%",
                backgroundColor: "#E4E4E4",
                padding: 2,
                fontSize: 8,
                fontWeight: 700,
                marginLeft: 0,
                color: "#000000",
                textAlign: "left",
                lineHeight: 1,
                borderStyle: "solid",
                borderColor: "#000000",
                borderWidth: 1,
                borderLeftWidth: 0,
                borderTopWidth: 0,
              }}
            >
              <Text style={{ margin: 2 }}>Mes y año: {month + "/" + year}</Text>
            </View>
          </View>
          {/**  Fin Fila 4 **/}

          {/** ESPACIO BLANCO  **/}

          {/**  Inicio tabla **/}

          <View style={{ flexDirection: "row", margin: "auto", marginTop: 8 }}>
            <View
              style={{
                width: "10%",
                backgroundColor: "#c1c7d0",
                padding: 2,
                fontSize: 8,
                fontWeight: 700,
                marginLeft: 0,
                color: "#000000",
                textAlign: "left",
                lineHeight: 1,
                borderStyle: "solid",
                borderColor: "#000000",
                borderWidth: 1,
                borderLeftWidth: 1,
                borderTopWidth: 1,
              }}
            >
              <Text style={{ margin: 2 }}>DIA</Text>
            </View>
            <View
              style={{
                width: "25%",
                backgroundColor: "#c1c7d0",
                padding: 2,
                fontSize: 8,
                fontWeight: 700,
                marginLeft: 0,
                color: "#000000",
                textAlign: "left",
                lineHeight: 1,
                borderStyle: "solid",
                borderColor: "#000000",
                borderWidth: 1,
                borderLeftWidth: 1,
                borderTopWidth: 1,
              }}
            >
              <Text style={{ margin: 2 }}>H. INICIO</Text>
            </View>
            <View
              style={{
                width: "25%",
                backgroundColor: "#c1c7d0",
                padding: 2,
                fontSize: 8,
                fontWeight: 700,
                marginLeft: 0,
                color: "#000000",
                textAlign: "left",
                lineHeight: 1,
                borderStyle: "solid",
                borderColor: "#000000",
                borderWidth: 1,
                borderLeftWidth: 1,
                borderTopWidth: 1,
              }}
            >
              <Text style={{ margin: 2 }}>H. FIN</Text>
            </View>
            <View
              style={{
                width: "15%",
                backgroundColor: "#c1c7d0",
                padding: 2,
                fontSize: 8,
                fontWeight: 700,
                marginLeft: 0,
                color: "#000000",
                textAlign: "left",
                lineHeight: 1,
                borderStyle: "solid",
                borderColor: "#000000",
                borderWidth: 1,
                borderLeftWidth: 1,
                borderTopWidth: 1,
              }}
            >
              <Text style={{ margin: 2 }}>TOTAL</Text>
            </View>
           
          </View>
          {/**  Fin Fila inicio **/}

          {/**  Inicio MES **/}
          {Array.apply(1, Array(31)).map(function (x, i) {
            return isMonth(i + 1, month, year, dataUser, festivos);
          })}
          {showLast(dataUser)}

          {/**  Fin MES **/}

          <View style={{ flexDirection: "row", margin: "auto" }}>
            <View
              style={{
                width: "50%",
                height: 60,
                backgroundColor: "#FFFFFF",
                padding: 2,
                fontSize: 8,
                fontWeight: 700,
                marginLeft: 0,
                color: "#000000",
                textAlign: "left",
                lineHeight: 1,
                borderStyle: "solid",
                borderColor: "#000000",
                borderWidth: 1,
                borderLeftWidth: 1,
                borderTopWidth: 1,
              }}
            >
              <Text style={{ margin: 2 }}>Firma de la empresa</Text>
            </View>
            <View
              style={{
                width: "50%",
                height: 60,
                backgroundColor: "#FFFFFF",
                padding: 2,
                fontSize: 8,
                fontWeight: 700,
                marginLeft: 0,
                color: "#000000",
                textAlign: "left",
                lineHeight: 1,
                borderStyle: "solid",
                borderColor: "#000000",
                borderWidth: 1,
                borderLeftWidth: 1,
                borderTopWidth: 1,
              }}
            >
              <Text style={{ margin: 2 }}>Firma del trabajador</Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", margin: "auto", marginTop: 20 }}>
            <View
              style={{
                width: "100%",
                height: 60,
                backgroundColor: "#FFFFFF",
                padding: 2,
                fontSize: 8,
                fontWeight: 700,
                marginLeft: 0,
                color: "#000000",
                textAlign: "left",
                lineHeight: 0,
                borderStyle: "solid",
                borderColor: "#000000",
                borderWidth: 0,
                borderLeftWidth: 0,
                borderTopWidth: 0,
              }}
            >
              <View>
                <Text style={{ margin: 2 }}>
                  En ___________________, a ______ de _____________ de ________
                </Text>
              </View>
              <View>
                <Text style={{ margin: 2 }}>
                  Registro realizado en cumplimiento del Art. 34.9 del texto
                  refundido de la Ley del Estatuto de los Trabajadores. La
                  empresa conservará los registros a que se refiere este
                  precepto durante cuatro años y permanecerán a disposición de
                  las personas trabajadoras, de sus representantes legales y de
                  la Inspección de Trabajo y Seguridad Social.
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const isMonth = (day, month, year, data,festivos) => {
  let mDay = "" + day;
  if (("" + day).length === 1) {
    mDay = "0" + day;
  }
  let mMonth = "" + month;
  if (("" + month).length === 1) {
    mMonth = "0" + month;
  }
  const mSearch = year + "-" + mMonth + "-" + mDay;

  const que = _.filter(data, function (o) {
    return o.startDay === mSearch;
  });
  if (que && que[0]) {
    if (que[0].typepermission?.id === -1) return showDayRow(day, que, mSearch);
    else if (que[0].typepermission?.id === 1) return showDayHol(day, que, mSearch);
    else if (que[0].typepermission?.id === 4) return showDayBaja(day, que, mSearch);
    else return showWhiteRow(day,year, month,festivos);
  }

  return showWhiteRow(day,year, month,festivos);
};

const showTotalHours = (data) => {
  let ret = 0;
  data.map((item) => {
    if (item.end && item.start) {
      var a = moment(item.end); //now
      var b = moment(item.start);
      if (item.typepermission.id === -1)
        ret += a.diff(b, "minutes"); // 745
    }
  });


  console.log("---->", data[0])
  if (data && data[0] && data[0].guard ){
      console.log("guard",data[0] )
      return toHoursAndMinutes(ret);
  }else {
      return toHoursAndMinutes(ret);
  }
};

const showHours = (data) => {
  let ret = 0;
  console.log("--data", data);
  data.map((item) => {
    if (item.end && item.start) {
      var a = moment(item.end); //now
      var b = moment(item.start);

      ret += a.diff(b, "minutes"); // 745
    }
  });

    if (data && data[0] && data[0].guard ){
        console.log("guard",data[0] )
        return  toHoursAndMinutes(ret) + " *"
    }else {
        return toHoursAndMinutes(ret);
    }
};

const showTotalHoursExtra = (dataT) => {
  let ret = 0;
  const mSalida = _.groupBy(dataT, "startDay");
  const keys = Object.keys(mSalida);
  keys.forEach((key, index) => {
    let data = mSalida[key];
    let total = 0;
    data.map((item) => {
      var a = moment(item.end); //now
      var b = moment(item.start);

      total += a.diff(b, "minutes"); // 745
    });
    if (data && data[0] && data[0].perDay) {
      if (total > data[0].perDay) {
        if (data[0].guard) {
          ret += total - data[0].perDay;
        } else {
          ret += 0;
        }
      } else ret += 0;
    } else {
      ret += 0;
    }
  });
  return toHoursAndMinutes(ret);
};

const showTotalHoursVolunteers = (dataT) => {
  let ret = 0;
  const mSalida = _.groupBy(dataT, "startDay");
  const keys = Object.keys(mSalida);
  keys.forEach((key, index) => {
    let data = mSalida[key];
    let total = 0;
    data.map((item) => {
      var a = moment(item.end); //now
      var b = moment(item.start);

      total += a.diff(b, "minutes"); // 745
    });
    if (data && data[0] && data[0].perDay) {
      if (total > data[0].perDay) {
        if (!data[0].guard) {
          ret += total - data[0].perDay;
        } else {
          ret += 0;
        }
      } else ret += 0;
    } else {
      ret += 0;
    }
  });
  return toHoursAndMinutes(ret);
};

const showHoursExtra = (data) => {
  let ret = 0;

  data.map((item) => {
    var a = moment(item.end); //now
    var b = moment(item.start);

    ret += a.diff(b, "minutes"); // 745
  });

  if (data && data[0] && data[0].perDay) {
    if (ret > data[0].perDay) {
      if (data[0].guard) {
        return ret - data[0].perDay;
      } else {
        return 0;
      }
    } else return 0;
  } else {
    return toHoursAndMinutes(ret);
  }
};

const showHoursVolunteer = (data) => {
  let ret = 0;
  data.map((item) => {
    var a = moment(item.end); //now
    var b = moment(item.start);

    ret += a.diff(b, "minutes"); // 745
  });

  if (data && data[0] && data[0].perDay) {
    if (ret > data[0].perDay) {
      if (!data[0].guard) {
        return ret - data[0].perDay;
      } else {
        return 0;
      }
    } else return 0;
  } else {
    return toHoursAndMinutes(ret);
  }
};

const isExtraOrdinario = (data) => {
  const mHours = showHours(data);
  const perDay = data[0].perDay;
  if (mHours > perDay) {
  }
};



function toHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return (hours < 9 ? ("0" + hours) : hours ) + ":" + (minutes < 9 ? ("0" + minutes) : minutes );
}



const showDayBaja = (number, data, date) => {
  return (
    <View wrap={true} style={{ flexDirection: "row", margin: "auto" }}>
      <View
        style={{
          width: "10%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
        <Text style={{ margin: 2 }}>{number}</Text>
      </View>
      <View
        style={{
          width: "25%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
          <Text style={{ margin: 2 }}>
            {"Baja Laboral"}
          </Text>
      </View>
      <View
        style={{
          width: "25%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
          <Text style={{ margin: 2 }}>
            {"Baja Laboral"}
          </Text>
      </View>
      <View
        style={{
          width: "15%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
          <Text style={{ margin: 2 }}>
           
          </Text>
      </View>
     
    </View>
  );
};

const showDayHol = (number, data, date) => {
  return (
    <View wrap={true} style={{ flexDirection: "row", margin: "auto" }}>
      <View
        style={{
          width: "10%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
        <Text style={{ margin: 2 }}>{number}</Text>
      </View>
      <View
        style={{
          width: "25%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
          <Text style={{ margin: 2 }}>
            {"Vacaciones"}
          </Text>
      </View>
      <View
        style={{
          width: "25%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
          <Text style={{ margin: 2 }}>
            {"Vacaciones"}
          </Text>
      </View>
      <View
        style={{
          width: "15%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
          <Text style={{ margin: 2 }}>
           
          </Text>
      </View>
     
    </View>
  );
};


const showDayFestivo = (number, data, date, name) => {
  return (
    <View wrap={true} style={{ flexDirection: "row", margin: "auto" }}>
      <View
        style={{
          width: "10%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
        <Text style={{ margin: 2 }}>{number}</Text>
      </View>
      <View
        style={{
          width: "25%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
          <Text style={{ margin: 2 }}>
            {"Festivo"} {name}
          </Text>
      </View>
      <View
        style={{
          width: "25%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
          <Text style={{ margin: 2 }}>
          {"Festivo"} {name}
          </Text>
      </View>
      <View
        style={{
          width: "15%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
          <Text style={{ margin: 2 }}>
           
          </Text>
      </View>
     
    </View>
  );
};


const showDayRow = (number, data, date) => {
  return (
    <View wrap={true} style={{ flexDirection: "row", margin: "auto" }}>
      <View
        style={{
          width: "10%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
        <Text style={{ margin: 2 }}>{number}</Text>
      </View>
      <View
        style={{
          width: "25%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
        {data.map((item) => (
          <Text style={{ margin: 2 }}>
            {moment(item.start).format("HH:mm")}
          </Text>
        ))}
      </View>
      <View
        style={{
          width: "25%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
        {data.map((item) => (
          <Text style={{ margin: 2 }}>{item.end ? moment(item.end).format("HH:mm") : "----"}</Text>
        ))}
      </View>
      <View
        style={{
          width: "15%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
       
          <Text style={{ margin: 2 }}>{showHours(data)}</Text>
       
      </View>
     
     
     
    </View>
  );
};


const isFestivo = (number, year, month, festivos) => {

  let mB = year + "-" + (month < 10 ? "0" + month : month )+  "-" + (number < 10 ? "0" + number : number );
  console.log(mB);
  let mEsta = festivos.filter(
    (item) => item.startDay === mB
  );
  console.log("mEsta", mEsta);
  if (mEsta && mEsta[0]){
    return "Festivo " + mEsta[0].name
  }else{
  return ""
  }

}

const showWhiteRow = (number,year, month, festivos) => {
  return (
    <View wrap={false} style={{  flexDirection: "row", margin: "auto" }}>
      <View
        style={{
          width: "10%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
        <Text style={{ margin: 2 }}>{number}</Text>
      </View>
      <View
        style={{
          width: "25%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
        <Text style={{ margin: 2 }}>{isFestivo(number, year, month, festivos)}</Text>
      </View>
      <View
        style={{
          width: "25%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
        <Text style={{ margin: 2 }}>{isFestivo(number, year, month, festivos)}</Text>
      </View>
      <View
        style={{
          width: "15%",
          backgroundColor: "#FFFFFF",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
        <Text style={{ margin: 2 }}></Text>
      </View>
      
    </View>
  );
};

const showLast = (data) => {
  return (
    <View wrap={false} style={{ flexDirection: "row", margin: "auto" }}>
      <View
        style={{
          width: "10%",
          backgroundColor: "#E4E4E4",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
        <Text style={{ margin: 2 }}>TOTAL</Text>
      </View>
      <View
        style={{
          width: "25%",
          backgroundColor: "#E4E4E4",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
        <Text style={{ margin: 2 }}></Text>
      </View>
      <View
        style={{
          width: "25%",
          backgroundColor: "#E4E4E4",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
        <Text style={{ margin: 2 }}></Text>
      </View>
      <View
        style={{
          width: "15%",
          backgroundColor: "#E4E4E4",
          padding: 2,
          fontSize: 7,
          fontWeight: 700,
          marginLeft: 0,
          color: "#000000",
          textAlign: "left",
          lineHeight: 1,
          borderStyle: "solid",
          borderColor: "#000000",
          borderWidth: 1,
          borderLeftWidth: 1,
          borderTopWidth: 1,
        }}
      >
        <Text style={{ margin: 2 }}>{showTotalHours(data)}</Text>
      </View>
      
    </View>
  );
};

const ShowMonth = (data) => {};

function TimeSheetView(props) {
  const { t, i18n } = props;
  const { path } = props.match;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);
  const [salida, setSalida] = useState([]);
  const [festivos, setFestivos] = useState([]);
  const [users, setUsers] = useState([]);
  const [dataUser, setDataUser] = useState([]);

  var timesheetData = useSelector(
    (state) => state.timesheetReducer.timesheetData
  );
  var timesheetDataError = useSelector(
    (state) => state.timesheetReducer.timesheetDataError
  );


  const getFestivos = () => {
    dispatch(festivosActions.getFestivos());
  };


  useEffect(() => {
    setLoading(false);
    if (timesheetData) {
      const mSalida = _.groupBy(timesheetData, "user.id");
      const keys = Object.keys(mSalida);
      setSalida(mSalida);
      let mUsers = [];
      keys.forEach((key, index) => {
        mUsers.push(mSalida[key][0].user);
      });
      setUsers(mUsers);
    }
    if (timesheetDataError) {
    }
  }, [timesheetData, timesheetDataError]);

  function onChange(date, dateString) {
    const mDate = moment(date);
    var lMonth = mDate.format("M");
    var lYear = mDate.format("YYYY");
    setMonth(lMonth);
    setYear(lYear);
  }

  const showTimeSheet = () => {
    if (month !== 0 && year !== 0){

      service.getToken("/v1/festivomes/"+year+"/"+month).then((response) => {
          setFestivos(response.data)
          dispatch(timesheetActions.getTimesheet(month, year));
      });
    

      
    }
    
  };

  const show = (record) => {
    setDataUser(salida[record.id]);
  };

  const columns = [
    {
      title: t("guards.employee"),
      key: "employee",
      render: (text, record) => (
        <span>
          {record.name} {record.surname}
        </span>
      ),
    },

    {
      title: t("actions"),
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <PDFDownloadLink
            document={MyDoc(salida[record.id], month, year, festivos)}
            fileName={
              year + "_" + month + "_" + salida[record.id][0].user.nif + ".pdf"
            }
          >
            {({ blob, url, loading, error }) =>
              loading ? <Spin /> : <DownloadOutlined />
            }
          </PDFDownloadLink>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: 10 }}>
      <h1>{t("menu.timesheet")}</h1>

      <Space direction="horizontal">
        {t("timesheet.selmonth")}
        <DatePicker onChange={onChange} picker="month" />
        <Button type="primary" onClick={() => showTimeSheet()}>
          {t("timesheet.go")}
        </Button>
      </Space><br />
      <Link to={"/admin/timesheetadd"} className="btn btn-sm btn-success mb-2">
        <Button type="primary" icon={<PlusCircleOutlined />}>
          {"Añadir fichaje a trabajador"}
        </Button>
      </Link>

      <Table columns={columns} dataSource={users} />
    </div>
  );
}
export default withTranslation()(TimeSheetView);
