import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import routes from "routes.js";
import Footer from "components/Footer/Footer.js";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Layout, Row, Col } from "antd";
let ps;
const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
  </Switch>
);

export default function Auth({ ...rest }) {

  return (
    <>
      <Layout className="authContainer"  >
        <Row justify="space-around" align="middle"  style={{minHeight:"100vh"}}>
          <Col span={24}>{switchRoutes}</Col>
        </Row>
      </Layout>
    </>
  );
}
