import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "_constants/actionTypes";
import { service } from "../_services/service";
import { history } from "_helpers/history";
export default function* watcherEmployees() {
    yield takeEvery(actionTypes.GET_EMPLOYEE, getEmployee);
    yield takeEvery(actionTypes.GET_EMPLOYEES, getEmployees);
    yield takeEvery(actionTypes.SAVE_EMPLOYEE, saveEmployee);
    yield takeEvery(actionTypes.NEW_EMPLOYEE, newEmployee);
    yield takeEvery(actionTypes.RESEND_EMPLOYEE, resend);

  yield takeEvery(actionTypes.GET_PERMISSIONS, getPermissions);
  yield takeEvery(actionTypes.SAVE_PERMISSIONS, savePermission);

  yield takeEvery(actionTypes.DEL_PERMISSIONS, delPermission);
    
}



function* delPermission( payload ) {
  console.log(payload);
    yield put({ type: actionTypes.START_REQUEST });
  
    try {
      let apiEndpoint = "/v1/permission/" + payload.payload;
      // console.log(payload);
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            service.deleteToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );
      console.log(res.status)
      if (res.status === 200) {
        yield put({
          type: actionTypes.GET_PERMISSIONS,
          payload: res.data,
        });
      } else {
        // console.log(res);
        yield put({
          type: actionTypes.GET_PERMISSIONS,
          payload: res.data,
        });
      }


    } catch (e) {
      yield put({ type: actionTypes.SAVE_PERMISSIONS_ERROR, payload: e });


      yield put({
        type: actionTypes.GET_PERMISSIONS,
        payload:"",
      });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }



function* getPermissions({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/permissioncompany";
    // console.log(payload);
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.getToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.GET_PERMISSIONS_DATA,
        payload: res.data,
      });
    } else {
      // console.log(res);
      yield put({
        type: actionTypes.GET_PERMISSIONS_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GET_PERMISSIONS_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}


function* savePermission( payload ) {
  console.log(payload);
    yield put({ type: actionTypes.START_REQUEST });
  
    try {
      let apiEndpoint = "/v1/permission/" + payload.payload;
      // console.log(payload);
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            service.putToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );
      console.log(res.status)
      if (res.status === 200) {
        yield put({
          type: actionTypes.SAVE_PERMISSIONS_DATA,
          payload: "ok",
        });
      } else {
        // console.log(res);
        yield put({
          type: actionTypes.SAVE_PERMISSIONS_ERROR,
          payload: res.data,
        });
      }

      yield put({
        type: actionTypes.CLEAR_PERMISSIONS_DATA,
        payload: res.data,
      });

      yield put({
        type: actionTypes.GET_PERMISSIONS,
        payload: res.data,
      });

    } catch (e) {
      yield put({ type: actionTypes.SAVE_PERMISSIONS_ERROR, payload: e });

      yield put({
        type: actionTypes.CLEAR_PERMISSIONS_DATA,
        payload: "o",
      });

      yield put({
        type: actionTypes.GET_PERMISSIONS,
        payload:"",
      });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }

function* resend(payload) {
  yield put({ type: actionTypes.START_REQUEST });

 try {
   let apiEndpoint = "/v1/employees/resend/" + payload.payload;
   const res = yield call(
     () =>
       new Promise((resolve, reject) => {
         service.putToken(apiEndpoint).then((response) => {
           resolve(response);
         });
       })
   );
   if (res.status === 200) {
     yield put({
       type: actionTypes.RESEND_EMPLOYEE_DATA,
       payload: res.data,
     });
     yield put({
       type: actionTypes.CLEAR_DATA_EMPLOYEE,
       payload: null,
     });


   } else {
     yield put({
       type: actionTypes.RESEND_EMPLOYEE_ERROR,
       payload: res.data,
     });
   }
 } catch (e) {
   yield put({ type: actionTypes.RESEND_EMPLOYEE_ERROR, payload: e });
 }
 yield put({ type: actionTypes.END_REQUEST });
}

function* getEmployee(payload) {
   yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/employees/employee/" + payload.payload;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.getToken(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.GET_EMPLOYEE_DATA,
        payload: res.data,
      });
      yield put({
        type: actionTypes.CLEAR_DATA_EMPLOYEE,
        payload: null,
      });


    } else {
      yield put({
        type: actionTypes.GET_EMPLOYEE_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GET_EMPLOYEE_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* saveEmployee({payload}) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/employees/employee/" + payload.id;
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.putToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.SAVE_EMPLOYEE_DATA,
        payload: res.data,
      });


      yield put({
        type: actionTypes.CLEAR_DATA_EMPLOYEE,
        payload: null,
      });
      
    } else {
      yield put({
        type: actionTypes.SAVE_EMPLOYEE_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.SAVE_EMPLOYEE_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}


function* newEmployee({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/employees/employee";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.postToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.NEW_EMPLOYEE_DATA,
        payload: res.data,
      });

      yield put({
        type: actionTypes.CLEAR_DATA_EMPLOYEE,
        payload: null,
      });

    } else {
      yield put({
        type: actionTypes.NEW_EMPLOYEE_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.NEW_EMPLOYEE_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}


function* getEmployees() {
    yield put({ type: actionTypes.START_REQUEST });
   
    try {
      let apiEndpoint = "/v1/employees";
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            service.getToken(apiEndpoint).then((response) => {
              resolve(response);
            });
          })
      );
      if (res) {
        if (res.status && res.status === 200 ) {
          yield put({ type: actionTypes.GET_EMPLOYEES_DATA, payload: res.data });
        } else {
          yield put({ type: actionTypes.GET_EMPLOYEES_ERROR, payload: "Error" });
        }
      } else {
        yield put({
          type: actionTypes.GET_EMPLOYEES_ERROR,
          payload: "Error",
        });
      }
    } catch (e) {
      yield put({
        type: actionTypes.GET_EMPLOYEES_ERROR,
        payload: "Error",
      });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }