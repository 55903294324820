import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { Form, Input, Button, Checkbox } from "antd";
import { Row, Col, Divider, Alert } from "antd";
import { useTranslation } from "react-i18next";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import logo from "assets/img/logob.png";
import { authActions } from "_actions/";
import pixel from "assets/img/pixel.png";
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
function LoginPage(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  var userLogin = useSelector((state) => state.authReducer.user);
  var errorLogin = useSelector((state) => state.authReducer.error);

  useEffect(() => {
    setLoading(false);
    if (userLogin) {
      setError(false);
      setError("");
    }
    if (errorLogin) {
      console.log(errorLogin);
      setError(true);
      if (errorLogin.codeMsg) {
        setErrorMsg(t(errorLogin.codeMsg));
      } else {
        setErrorMsg(errorLogin);
      }
    }
  }, [userLogin, errorLogin]);

  const onFinish = (values) => {
     setLoading(true);
    dispatch(authActions.login(values.email, values.password));
  };

  return (
    <>
      <Row justify="space-around" align="middle">
        <Col xl={7} lg={7} md={10} sm={18} xs={20} className="form-padding">
          <div style={{ textAlign: "center" }}>
            <img src={logo} />
          </div>

          <Divider orientation="left"></Divider>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <h4 className="gwh1" style={{ textAlign: "center" }}>
              {t("login.login")}
            </h4>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: t("login.emailIncorrect"),
                },
                {
                  required: true,
                  message: t("login.emailMandatory"),
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={t("login.email")}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t("login.passwordMandatory"),
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder={t("login.password")}
              />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>{t("login.rememberMe")}</Checkbox>
              </Form.Item>

              <a className="login-form-forgot" href="/auth/forgot">
                {t("login.forgotPassword")}
              </a>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                loading={loading}
                htmlType="submit"
                className="login-form-button"
              >
                {t("login.login")}
              </Button>
              {error && <Alert message={errorMsg} type="error" />}
            </Form.Item>
          </Form>
          Powered by:<br />
          <img style={{width: 100}} src={pixel}  />
        </Col>
      </Row>
    </>
  );
}
export default withTranslation()(LoginPage);
