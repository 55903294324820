
import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "_constants/actionTypes";
import { service } from "../_services/service";
import { history } from "_helpers/history";
export default function* watcherDashboard() {
    yield takeEvery(actionTypes.GET_DASHBOARD, getDashboard);
    
}



function* getDashboard(payload) {
   yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/dashboard";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.getToken(apiEndpoint).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.GET_DASHBOARD_DATA,
        payload: res.data,
      });
     


    } else {
      yield put({
        type: actionTypes.GET_DASHBOARD_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GET_DASHBOARD_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
