import actionTypes from "_constants/actionTypes";
const initialState = { loading: false, queue: 0, APIactions: 0 };

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TIMESHEET_DATA:
      return {
        ...state,
        timesheetDataError: null,
        timesheetData: action.payload,
      };
    case actionTypes.GET_TIMESHEET_ERROR:
      return {
        ...state,
        timesheetData: null,
        timesheetDataError: action.payload,
      };

      case actionTypes.GET_CLEAR_TIMESHEET_DATA:
        return {
          ...state,
          timesheetData: null,
        }

    default:
      return {
        ...state,
      };
  }
};
